import ApiClient from './client'
const client = new ApiClient()

export default {
  list(paginationInfo) {
    return client.post('api/Menu/MenusListPaginated', paginationInfo, {
      'Content-Type': 'application/json',
    })
  },

  listShort() {
    return client.get('api/Menu/ListShortMenu', {
      'Content-Type': 'application/json',
    })
  },

  details(id) {
    return client.get('/api/Menu/MenuDetail/' + id, {
      'Content-Type': 'application/json',
    })
  },

  create(menuInfo) {
    return client.post('/api/Menu/MenuAdd/', menuInfo, {
      'Content-Type': 'application/json',
    })
  },

  update(menuInfo) {
    return client.post('/api/Menu/MenuEditAdmin/', menuInfo, {
      'Content-Type': 'application/json',
    })
  },

  archive(archiveItem) {
    return client.post('api/Menu/MenuArchive/', archiveItem, {
      'Content-Type': 'application/json',
    })
  },

  duplicate(id) {
    return client.post(`/api/Menu/${id}/Duplicate`, {
      'Content-Type': 'application/json',
    })
  },

  changeStatus(id, status, actualPrice, reason) {
    if (status === 1) {
      reason = ''
    } else if (status === 2) {
      actualPrice = 0
    }
    return client.post(
      '/api/Menu/MenuApprove',
      {
        id: id,
        status: status,
        actualPrice: actualPrice,
        reason: reason,
      },
      {
        'Content-Type': 'application/json',
      },
    )
  },

  pendingCount() {
    return client.get('/api/Menu/GetPendingMenuCount', {
      'Content-Type': 'application/json',
    })
  },

  show(id, isShow) {
    return client.put(`/api/Booking/Rate/${id}/${isShow}`, {
      'Content-Type': 'application/json',
    })
  },
  delete(id) {
    return client.delete(`/api/Menu/MenuDelete/${id}`, {
      'Content-Type': 'application/json',
    })
  },
  availableDates(chefId, availableDates) {
    return client.post(`/api/Menu/MenuEditAvailableDates/${chefId}`, availableDates, {
      'Content-Type': 'application/json',
    })
  },
  syncMenuAvailableDates(menuId) {
    return client.post(`/api/Menu/SyncMenuAvailableDates/${menuId}`)
  },
}
