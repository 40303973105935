import ApiClient from './client'
const client = new ApiClient()

export default {
  getListCities() {
    return client.get(`/api/Booking/ListCities`, {
      'Content-Type': 'application/json',
    })
  },
  getHomeScreenInfo(cityId) {
    return client.get(`/api/Common/GetHomeScreenInfo/${cityId}`, {
      'Content-Type': 'application/json',
    })
  },
  getConfiguration() {
    return client.get('/api/Common/GetHomeScreenInfo', {
      'Content-Type': 'application/json',
    })
  },

  setConfiguration(confInfo) {
    return client.post('/api/Common/UpdateHomeScreenInfo', confInfo, {
      'Content-Type': 'application/json',
    })
  },

  setHomeConfiguration(cityId, confInfo) {
    return client.post(`/api/Common/UpdateHomeScreenInfo/${cityId}`, confInfo, {
      'Content-Type': 'application/json',
    })
  },
}
