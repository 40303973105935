import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Builder from './Builder'
// import Dashboard from "./Dashboard";
import GoogleMaterialPage from './google-material/GoogleMaterialPage'
import ReactBootstrapPage from './react-bootstrap/ReactBootstrapPage'
import DocsPage from './docs/DocsPage'
import TagList from '../home/TagList'
import UtensilList from '../home/UtensilList'
import UtensilGroups from '../home/UtensilGroups'
import CategoryList from '../home/CategoryList'
import ConfigList from '../home/ConfigList'
import UnitList from '../home/Units'
import MenuList from '../home/MenuList'
import TimePeriod from '../home/TimePeriod'
import ChefList from '../home/ChefList'
import AllergyList from '../home/AllergyList'
import PreferenceList from '../home/PreferenceList'
import RestrictionList from '../home/RestrictionList'
import ReviewList from '../home/ReviewList'
import MenuDetails from './MenuDetails'
import MenuCreate from './MenuCreate'
import ChefDetails from './ChefDetails'
import CityList from './CityList'
import BookingList from './BookingList'
import BookingCreate from './BookingCreate'
import HomeConfiguration from './HomeConfiguration'
import HomeScreenCategory from './HomeScreenCategory'
import ConversationList from './ConversationList'
import CourseType from './CourseType'
import CouponList from './CouponList'
import Holidays from './Holidays'

export default function HomePage() {
  return (
    <Switch>
      {<Redirect exact from="/" to="/booking" />}
      <Route path="/builder" component={Builder} />
      <Route path="/google-material" component={GoogleMaterialPage} />
      <Route path="/react-bootstrap" component={ReactBootstrapPage} />
      <Route path="/docs" component={DocsPage} />
      <Route path="/tags" component={TagList} />
      <Route path="/category" component={CategoryList} />
      <Route path="/utensils" component={UtensilList} />
      <Route path="/utensilgroups" component={UtensilGroups} />
      <Route path="/units" component={UnitList} />
      <Route path="/config" component={ConfigList} />
      <Route path="/reviews" component={ReviewList} />
      <Route path="/menu" component={MenuList} />
      <Route path="/menu-details" component={MenuDetails} />
      <Route path="/menu-create" component={MenuCreate} />
      <Route path="/timeperiod" component={TimePeriod} />
      <Route path="/chef" component={ChefList} />
      <Route path="/chef-details" component={ChefDetails} />
      <Route path="/allergy" component={AllergyList} />
      <Route path="/home-screen-cat" component={HomeScreenCategory} />
      <Route path="/holidays" component={Holidays} />
      <Route path="/course-type" component={CourseType} />
      <Route path="/preference" component={PreferenceList} />
      <Route path="/restrictions" component={RestrictionList} />
      <Route path="/city" component={CityList} />
      <Route path="/booking" component={BookingList} />
      <Route path="/booking-create" component={BookingCreate} />
      <Route path="/home-screen-conf" component={HomeConfiguration} />
      <Route path="/coupons" component={CouponList} />
      <Route path="/conversations" component={ConversationList} />
      <Redirect to="/error/error-v4" />
    </Switch>
  )
}
