import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Table, Form, Button, Input, InputNumber, Switch, notification } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'

import { confirmOperation } from '../../components/Helper-functions'
import { Portlet, PortletHeader } from '../../partials/content/Portlet'

import configClient from '../api/config'
export default function AddOns() {
  const [formRef] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [addOnsList, setAddOnsList] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const isEditing = record => record.id === editingKey

  const edit = record => {
    formRef.setFieldsValue({
      ...record,
    })
    setEditingKey(record.id)
  }

  const fetchAddOnList = useCallback(() => {
    setLoading(true)
    configClient
      .addOns()
      .then(res => {
        if (res.status === 200) {
          setAddOnsList(res.data)
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    fetchAddOnList()
    // eslint-disable-next-line
  }, [])

  const handleActiveAdd = (data, e) => {
    const row = {
      amount: data.amount,
      name: data.name,
      isActive: e,
    }
    setLoading(true)
    configClient
      .updateAddOns(data.id, { ...row })
      .then(res => {
        if (res.status === 200) {
          fetchAddOnList()
          setEditingKey('')
          notification.success({ message: 'Add ons has been successfully updated!' })
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }
  const handleSave = async key => {
    try {
      const row = await formRef.validateFields()
      const newData = [...addOnsList]
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        const data = {
          ...row,
          isActive: formRef.getFieldValue('isActive'),
        }
        setLoading(true)
        configClient
          .updateAddOns(key, { ...data })
          .then(res => {
            if (res.status === 200) {
              fetchAddOnList()
              setEditingKey('')
              notification.success({ message: 'Add ons has been successfully updated!' })
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false))
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const addOnsColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name', align: 'center', editable: true, width: '30%' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', align: 'center', editable: true, width: '30%' },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      editable: false,
      render: (_, record) => {
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={record.isActive}
            onChange={e =>
              confirmOperation({
                content: 'Are your sure change status?',
                okText: 'Yes',
                onOk: () => handleActiveAdd(record, e),
              })
            }
          />
        )
      },
      width: '20%',
    },
    {
      title: 'Actions',
      align: 'center',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <div className="middle-centered">
              <Button
                type="primary"
                onClick={() =>
                  confirmOperation({
                    content: 'Want to save your changes ?',
                    okText: 'Yes',
                    onOk: () => handleSave(record.id),
                    onCancel: () => setEditingKey(''),
                  })
                }
              >
                Save
              </Button>
              <Button type="danger" onClick={() => setEditingKey('')}>
                Cancel
              </Button>
            </div>
          </span>
        ) : (
          <div className="middle-centered">
            <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Button>
          </div>
        )
      },
    },
  ]
  const mergedColumns = addOnsColumns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'amount' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <>
      <Portlet>
        <PortletHeader title="Add-Ons" />
        <Row>
          <Col xs={24}>
            <Form form={formRef} component={false}>
              <Table
                className="add-list-table"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={addOnsList}
                loading={loading}
                columns={mergedColumns}
                rowClassName="editable-row"
                rowKey={row => row.id}
              />
            </Form>
          </Col>
        </Row>
      </Portlet>
    </>
  )
}

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
