import ApiClient from './client'
const client = new ApiClient()

export default {
  list() {
    return client.get('/api/Booking/ListCities', {
      'Content-Type': 'application/json',
    })
  },

  create(name) {
    return client.post('/api/Booking/CityAdd', name, {
      'Content-Type': 'application/json',
    })
  },
  edit(cityData) {
    return client.put('/api/Booking/CityUpdate', cityData, {
      'Content-Type': 'application/json',
    })
  },
  delete(id) {
    return client.delete('/api/Booking/CityDelete/' + id, {
      'Content-Type': 'application/json',
    })
  },
  deleteZips(id) {
    return client.delete(`/api/Booking/CityZip/${id}`, {
      'Content-Type': 'application/json',
    })
  },
  attachZips(id, zipList) {
    return client.post(`/api/Booking/CityZip/${id}`, zipList, {
      'Content-Type': 'application/json',
    })
  },
  zipList(id) {
    return client.get(`/api/Booking/CityZip/${id}`, {
      'Content-Type': 'application/json',
    })
  },
  addZip(cityId, zip) {
    return client.post(`/api/Booking/CityZip/${cityId}/${zip}`, {
      'Content-Type': 'application/json',
    })
  },
  deleteZip(cityId, zip) {
    return client.delete(`/api/Booking/CityZip/${cityId}/${zip}`, {
      'Content-Type': 'application/json',
    })
  },
  checkZip(zip) {
    return client.get(`/api/Booking/CityZipCheck/${zip}`, {
      'Content-Type': 'application/json',
    })
  },
}
