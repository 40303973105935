import axios from 'axios'
import httpStatus from 'http-status-codes'
import worker_script from '../../components/worker'
import Cookies from 'universal-cookie'
import querystring from 'querystring'

var auth = {
  client_id: 'web',
  client_secret: 'ecd6a6fd-195b-42db-8edf-e4c6250249a7',
  url: 'api/auth/login',
}

const getClient = (token = false) => {
  const options = {
    baseURL: 'https://savorwe-backend.azurewebsites.net/',
    //baseURL: 'https://savorwe-backend-staging.azurewebsites.net/', 
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': token ? 'application/x-www-form-urlencoded' : 'application/json',
    validateStatus: function(status) {
      return status >= httpStatus.OK && status <= httpStatus.MULTI_STATUS
    },
  }

  if (localStorage.getItem('access_token')) {
    options.headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    }
  }
  return axios.create(options)
}

export default class ApiClient {
  async get(url) {
    try {
      const response = await getClient().get(url)
      return await Promise.resolve(response)
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/auth/login'
      }
      Promise.reject(error)
    }
  }

  async post(url, data = {}) {
    try {
      const response = await getClient().post(url, data)
      return await Promise.resolve(response)
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/auth/login'
      }
      Promise.reject(error)
    }
  }

  async put(url, data = {}) {
    try {
      const response = await getClient().put(url, data)
      return await Promise.resolve(response)
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/auth/login'
      }
      Promise.reject(error)
    }
  }

  async delete(url) {
    try {
      const response = await getClient().delete(url)
      return await Promise.resolve(response)
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.href = '/auth/login'
      }
      Promise.reject(error)
    }
  }

  async login(email, password) {
    let cookies = new Cookies()
    var uuid = require('uuid')
    let device_id = cookies.get('deviceId')
    if (device_id === undefined) {
      device_id = uuid.v4()
      cookies.set('deviceId', device_id)
    }
    var str = querystring.stringify({
      grant_type: 'password',
      username: email,
      password: password,
      scope: 'offline_access',
      client_id: 'web',
      client_secret: auth.client_secret,
      device_id: device_id,
      notification_key: '',
    })
    try {
      const response = await getClient().post(auth.url, str)
      return await Promise.resolve(response)
    } catch (error) {
      if (error.response?.status === 401) localStorage.clear()
      Promise.reject(error)
    }
  }
}

var myWorker = new Worker(worker_script)

myWorker.onmessage = m => {
  if (localStorage.getItem('isAuthenticated') === 'true') {
    if (new Date().getTime() > +localStorage.getItem('expire_date')) {
      getClient(true)
        .post(
          auth.url,
          querystring.stringify({
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refresh_token'),
            client_id: auth.client_id,
            client_secret: auth.client_secret,
          }),
        )
        .then(response => {
          localStorage.setItem('access_token', response.data.access_token)
          let date = new Date()
          date.setHours(date.getHours(), date.getMinutes(), date.getSeconds() + response.data.expires_in)
          localStorage.setItem('expire_date', date.getTime())
          localStorage.setItem('isAuthenticated', true)
        })
        .catch(error => {
          localStorage.setItem('isAuthenticated', false)
        })
    }
  }
}
myWorker.postMessage('im from main')
