import ApiClient from './client';
const client = new ApiClient();

export default {
    list(paginationInfo) {
        return client.post('api/account/utensilslistpaginated', paginationInfo, {
            'Content-Type': 'application/json'
        });
    },

    create(name) {
        return client.post('api/account/utensiladd', {
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    update(id, name) {
        return client.post('api/account/utensilupdate', {
            'id': id,
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    delete(id) {
        return client.delete('api/account/utensildelete/' + id, {
            'Content-Type': 'application/json'
        });
    }
}   