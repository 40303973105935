import React from 'react'

import httpStatus from 'http-status-codes'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import { Input, Select, Button, Row, Col, InputNumber, Typography, DatePicker, Spin, Table, notification } from 'antd'
import { PushpinOutlined } from '@ant-design/icons'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import accountClient from '../api/account'
import customerClient from '../api/customer'
import bookingClient from '../api/booking'
import addonsClient from '../api/config'

import moment from 'moment'
import LocationSearchInput from '../../components/LocationSearchInput'
import { LazyImage } from '../../components/LazyImage'
import '../styles/booking.scss'
import AddCardModal from './AddCardModal'
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography
function disabledDate(current) {
  return current && current < moment().endOf('day')
}
const ActiveCircle = (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <span className="text-center booking-dot"></span>
  </div>
)
function currencyFormat(num) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}
class BookingCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingSpin: false,
      menuLoadingSpin: false,
      booking: {
        id: 0,
      },
      chefOptions: [],
      customerOptions: [],
      addonsOptions: [],
      alleriesOptions: [],
      menuOptions: [],
      cardOptions: [],
      giftCard: '',
      couponCode: '',
      modalVisible: false,
      submitDisable: false,
      postalCode: '',
      taxRate: 0,
      feeDetails: {},
    }
    this.myRef = React.createRef()
    this.handleAddress = this.handleAddress.bind(this)
    this.fetchChefList = this.fetchChefList.bind(this)
    this.fetchAllergiesList = this.fetchAllergiesList.bind(this)
    this.fetchAddonsList = this.fetchAddonsList.bind(this)
    this.fetchCustomerCardDetails = this.fetchCustomerCardDetails.bind(this)
    this.submitBooking = this.submitBooking.bind(this)
    this.hideAddModal = this.hideAddModal.bind(this)
    this.refreshList = this.refreshList.bind(this)
  }

  async componentDidMount() {
    this.setState({
      booking: {
        id: 0,
        address: '',
        latitude: 0,
        longitude: 0,
        chefId: 0,
        servings: 4,
        type: 0,
        date: new Date().toISOString(),
        isAllergy: false,
        isParking: false,
        hasPets: false,
        cardId: 0,
        addOnIds: [],
      },
    })
    this.myRef.current.focus()
    await this.fetchChefList()
    await this.fetchAllergiesList()
    await this.fetchAddonsList()
    this.setState({
      loadingSpin: false,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.booking.menuDetails) {
      if (
        prevState.booking.menuDetails !== this.state.booking.menuDetails ||
        prevState.booking.addOnIds !== this.state.booking.addOnIds ||
        prevState.booking.date !== this.state.booking.date ||
        prevState.booking.servings !== this.state.booking.servings ||
        prevState.postalCode !== this.state.postalCode
      ) {
        this.fetchReviewBooking()
      }
    }
  }

  //#region API List Calls
  async fetchChefList() {
    await accountClient.listChef().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          chefOptions: res.data,
        })
      }
    })
  }
  async fetchAddonsList() {
    await addonsClient.addOns().then(res => {
      if (res.status === 200) {
        this.setState({
          addonsOptions: res.data.filter(add => add.isActive === true),
        })
      }
    })
  }
  async fetchAllergiesList() {
    await bookingClient.allergiesList().then(res => {
      if (res.status === 200) {
        this.setState({
          alleriesOptions: res.data,
        })
      }
    })
  }
  async fetchTaxRate(zipCode) {
    await bookingClient.taxRate(zipCode).then(res => {
      if (res.status === 200) {
        this.setState({
          taxRate: res.data,
        })
      }
      if (res.status === 204) {
        this.setState({
          taxRate: '',
        })
      }
    })
  }

  async fetchReviewBooking() {
    var data = this.state
    const reviewData = {
      menuId: data.booking.menuDetails.id,
      servings: data.booking.servings,
      coupon: data.giftCard,
      couponId: data.couponCode,
      date: data.booking.date,
      addOnIds: data.booking.addOnIds ? data.booking.addOnIds : [],
      zipCode: data.postalCode.toString().length > 4 ? data.postalCode : `${"0" + data.postalCode}`,
    }
    if (data.booking.longitude > 0 || data.booking.latitude > 0) {
      const customerId = data.booking.customerId
      await bookingClient
        .bookingReview(customerId, reviewData)
        .then(res => {
          if (res.status === 200) {
            this.setState({
              feeDetails: res.data,
            })
          }
        })
        .catch(() => {
          notification.error({ message: 'Something went wrong! Please try again.' })
        })
    } else {
      notification.error({ message: 'Location is not available' })
    }
  }

  checkCustomerAvailability(customer) {
    if (customer.length !== 0) {
      customerClient.checkCustomer(customer).then(res => {
        if (res.status === 200) {
          this.setState({ customerOptions: res.data })
        }
      })
    } else {
      this.setState({
        customerOptions: [],
      })
    }
  }
  fetchListShortMenu(chefId) {
    customerClient.listShortMenu(chefId).then(res => {
      if (res.status === 200) {
        this.setState({ menuOptions: res.data })
      }
    })
  }
  async fetchMenuDetails(menuId) {
    const context = this
    this.setState({
      menuLoadingSpin: true,
    })
    await bookingClient
      .menuDetails(menuId, { currentTime: `${new Date().toISOString()}` })
      .then(res => {
        if (res.status === 200) {
          context.setState(prevState => ({
            booking: {
              ...prevState.booking,
              menuDetails: res.data,
            },
          }))
          this.setState({
            menuLoadingSpin: false,
          })
        }
      })
      .catch(err => {
        this.setState({
          menuLoadingSpin: false,
        })
      })
  }
  async fetchCustomerCardDetails(e) {
    this.setState({
      loadingSpin: true,
    })
    await bookingClient
      .cardList(e)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            cardOptions: res.data,
          })
          this.setState({
            loadingSpin: false,
          })
        }
      })
      .catch(err => {
        this.setState({
          loadingSpin: false,
        })
      })
  }
  handleValidation() {
    var resultArray = []
    const { booking } = this.state
    if (!booking.customerId) {
      document.getElementById('customer-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('customer-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!booking.chefId) {
      document.getElementById('chef-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('chef-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!booking.menuId) {
      document.getElementById('menu-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('menu-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!booking.time) {
      document.getElementById('time-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('time-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!booking.address) {
      document.getElementById('address-input').style.border = '1px solid red'
      document.getElementById('address-input').style.borderRadius = '5px'
      document.getElementById('address-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!booking.cardId) {
      document.getElementById('card-input').style.border = '1px solid red'
      document.getElementById('card-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (booking.allergies?.length === 0) {
      document.getElementById('allergies-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('allergies-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }

    return resultArray.length === 0
  }
  async hideAddModal() {
    await this.setState({ modalVisible: false })
  }
  refreshList() {
    this.fetchCustomerCardDetails(localStorage.getItem('customerId'))
  }
  handleAddress(addr, lat, lng, pstCode) {
    document.getElementById('address-input').style.borderColor = '#d9d9d9'
    document.getElementById('address-input-val').style.display = 'none'
    const context = this
    context.setState(prevState => ({
      booking: {
        ...prevState.booking,
        address: addr,
        latitude: lat,
        longitude: lng,
        errorAddress: false,
      },
      postalCode: pstCode,
    }))
  }

  getSmallGroup() {
    return parseFloat((this.getSubtotalPrice() * this.state.booking.menuDetails.surcharge) / 100).toFixed(2)
  }
  getSubtotalPrice() {
    return parseFloat(this.state.booking.menuDetails.price * this.state.booking.servings).toFixed(2)
  }
  getEstimatedTax() {
    return this.state.taxRate && parseFloat((this.getSubtotalPrice() * this.state.taxRate.value) / 100).toFixed(2)
  }
  getFees() {
    return parseFloat((this.getSubtotalPrice() * this.state.booking.menuDetails.fees[0].value) / 100).toFixed(2)
  }
  getTotalAmount() {
    const servings = parseFloat(this.state.booking.servings >= 4 ? 0 : (this.getSubtotalPrice() * this.state.booking.menuDetails.surcharge) / 100).toFixed(2)
    const fees = this.getFees()
    const tax = this.getEstimatedTax()
    const subTotal = this.getSubtotalPrice()

    return parseFloat(Number(servings) + Number(fees) + Number(tax) + Number(subTotal)).toFixed(2)
  }
  getTotalPrice() {
    const servings = parseFloat(this.state.booking.servings >= 4 ? 0 : (this.getSubtotalPrice() * this.state.booking.menuDetails.surcharge) / 100).toFixed(2)
    const fees = this.getFees()
    const giftCard = this.state.booking?.giftDetails?.remainingValue === undefined ? 0 : this.state.booking?.giftDetails?.remainingValue
    const couponAmount = this.state.booking?.couponDetails?.amount_off ? this.state.booking?.couponDetails?.amount_off / 100 : 0
    const couponPercent = this.state.booking?.couponDetails?.percent_off ? this.getSubtotalPrice() / this.state.booking?.couponDetails?.percent_off : 0
    const tax = this.getEstimatedTax()
    const subTotal = this.getSubtotalPrice()
    return parseFloat(
      Number(servings) + Number(fees) + Number(tax) + Number(subTotal) - Number(giftCard) - Number(couponAmount) - Number(couponPercent),
    ).toFixed(2)
  }
  applyGiftCard(code) {
    const context = this
    context.setState(prevState => ({
      booking: {
        ...prevState.booking,
        giftDetails: code,
      },
    }))
    this.fetchReviewBooking()
    // const context = this
    // bookingClient.giftCard(code).then(res => {
    //   if (res.status === 200) {
    //     if (res.data.canBeRedeemed) {
    //       context.setState(prevState => ({
    //         booking: {
    //           ...prevState.booking,
    //           giftDetails: res.data,
    //         },
    //       }))
    //     } else {
    //       notification.error({
    //         message: `This code has already been redeemed.`,
    //       })
    //     }
    //   }
    // })
  }
  applyCoupon(couponCode) {
    const context = this
    context.setState(prevState => ({
      booking: {
        ...prevState.booking,
        couponDetails: couponCode,
      },
    }))
    this.fetchReviewBooking()
    // bookingClient
    //   .couponCode({ code: `${couponCode}`, amount: this.getSubtotalPrice() })
    //   .then(res => {
    //     if (res.status === 200) {
    //       context.setState(prevState => ({
    //         booking: {
    //           ...prevState.booking,
    //           couponDetails: res.data,
    //         },
    //       }))
    //     }
    //   })
    //   .catch(() => {})
    //   .finally(() =>
    //     this.setState({
    //       loading: false,
    //     }),
    //   )
  }
  onSelectChange = (record, selected, selectRow) => {
    const context = this
    context.setState(prevState => ({
      booking: {
        ...prevState.booking,
        cardId: record.cardId,
      },
    }))
    document.getElementById('card-input').style.borderColor = '#d9d9d9'
    document.getElementById('card-input-val').style.display = 'none'
  }

  submitBooking() {
    const result = this.handleValidation()
    if (result) {
      this.setState({
        submitDisable: true,
      })
      var bookingData = this.state
      var date = moment(bookingData.booking?.date).format('MM/DD/YYYY')
      var timeAndDate = moment(date + ' ' + bookingData.booking.time).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')
      const data = {
        menuId: bookingData.booking.menuDetails.id,
        servings: bookingData.booking.servings,
        coupon: bookingData.giftCard,
        couponId: bookingData.couponCode,
        date: timeAndDate,
        addOnIds: bookingData.booking.addOnIds ? bookingData.booking.addOnIds : [],
        zipCode: bookingData.postalCode.toString().length > 4 ? bookingData.postalCode : `${"0" + bookingData.postalCode}`,
        isAllergy: bookingData.booking.isAllergy,
        allergies: bookingData.booking.allergies ? bookingData.booking.allergies : [],
        allergyNote: bookingData.booking.allergyNote ? bookingData.booking.allergyNote : '',
        isParking: bookingData.booking.isParking,
        hasPets: bookingData.booking.hasPets,
        parkingNote: bookingData.booking.parkingNote ? bookingData.booking.parkingNote : '',
        cardId: bookingData.booking.cardId,
        address: bookingData.booking.address,
        latitude: bookingData.booking.latitude.toString(),
        longitude: bookingData.booking.longitude.toString(),
        type: bookingData.booking.type,
      }
      bookingClient
        .createBooking(bookingData.booking.customerId, data)
        .then(res => {
          if (res.status === 200) {
            notification.success({
              message: 'Booking Saved',
              description: 'Booking has been successfully created!',
            })
            this.setState({
              loadingSpin: true,
              submitDisable: false,
            })
            const context = this
            setTimeout(function() {
              context.props.history.push({
                pathname: '/booking',
              })
            }, 1500)
          }
        })
        .catch(error => {
          console.error(`Request failed: ${error}`)
          notification.error({
            message: 'Oops! Something went wrong.',
          })
          this.setState({ loadingSpin: false, submitDisable: false })
        })
    }
  }

  render() {
    const {
      loadingSpin,
      chefOptions,
      customerOptions,
      menuOptions,
      alleriesOptions,
      cardOptions,
      booking,
      menuLoadingSpin,
      giftCard,
      couponCode,
      modalVisible,
      taxRate,
      addonsOptions,
      feeDetails,
    } = this.state
    var context = this
    const rowSelection = {
      onSelect: this.onSelectChange,
      type: 'radio',
    }
    const columns = [
      {
        title: '',
        dataIndex: 'isDefault',
        className: 'text-center',
        width: '20px',
        render: isDefault => isDefault && ActiveCircle,
      },
      {
        title: 'Card number',
        key: 'last4',
        sorter: false,
        className: 'text-center',
        render: (record, idx) => {
          return (
            <div key={idx}>
              <div className="text-left">**** **** **** {record.last4}</div>
            </div>
          )
        },
      },
      {
        title: 'Type',
        dataIndex: 'brand',
        key: 'brand',
        sorter: false,
        responsive: ['md'],
        className: 'text-center',
      },
      {
        title: 'Exp',
        sorter: false,
        responsive: ['md'],
        className: 'text-center',
        render: (record, idx) => {
          return (
            <div key={idx}>
              {record.expMonth}/{record.expYear}
            </div>
          )
        },
      },
    ]
    return (
      <Spin spinning={loadingSpin}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Create Booking" />
          <PortletBody>
            <div style={{ marginBottom: '20px' }}>
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: '/booking',
                  })
                }}
              >
                <i className="kt-menu__ver-arrow la la-angle-left" />
                Back to Booking List
              </Button>
            </div>
            <Row gutter={[12, 12]}>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Customer Search <span style={{ color: 'red' }}> *</span>
                </Text>

                <Select
                  id="customer-select"
                  showSearch
                  ref={this.myRef}
                  autoFocus
                  allowClear={false}
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Please enter a customer"
                  onSearch={e => {
                    this.checkCustomerAvailability(e)
                  }}
                  onChange={e => {
                    document.getElementById('customer-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                    document.getElementById('customer-select-val').style.display = 'none'
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        customerId: e,
                      },
                    }))
                    localStorage.setItem('customerId', e)
                    this.fetchCustomerCardDetails(e)
                  }}
                >
                  {customerOptions.map((item, idx) => (
                    <Option value={item.id} key={idx}>
                      {item.name + ` (` + item.email + `)`}
                    </Option>
                  ))}
                </Select>
                <span id="customer-select-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Chef<span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="chef-select"
                  showSearch
                  allowClear={false}
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Please select a chef"
                  onChange={e => {
                    document.getElementById('chef-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                    document.getElementById('chef-select-val').style.display = 'none'
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        chefId: e,
                      },
                    }))
                    this.fetchListShortMenu(e)
                  }}
                >
                  {chefOptions.map((item, idx) => (
                    <Option value={item.id} key={idx}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                <span id="chef-select-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>
              <Col xs={24} md={12} lg={8} className="location-search-container">
                <Text strong={true}>
                  Event Location<span style={{ color: 'red' }}> *</span>
                </Text>
                <div id="address-input">
                  <span style={{ position: 'absolute', top: '25px', left: '15px', zIndex: 10 }}>
                    <PushpinOutlined className="phoneInput-icon" style={{ color: 'rgba(0,0,0,.25)', fontSize: '14px' }} />
                  </span>
                  <LocationSearchInput required handleAddress={this.handleAddress} name="address" address={booking.address} isPrimary={true} />
                </div>
                <span id="address-input-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Menu<span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="menu-select"
                  showSearch
                  allowClear={false}
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Please select a menu"
                  onChange={e => {
                    document.getElementById('menu-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                    document.getElementById('menu-select-val').style.display = 'none'

                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        menuId: e,
                      },
                    }))
                    this.fetchMenuDetails(e)
                  }}
                >
                  {menuOptions.map((item, idx) => (
                    <Option value={item.id} key={idx}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                <span id="menu-select-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  How many people <span style={{ color: 'red' }}> *</span>
                </Text>
                <InputNumber
                  id="servings-input"
                  style={{ width: '100%' }}
                  min={2}
                  max={100}
                  defaultValue={4}
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        servings: e,
                      },
                    }))
                  }}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Experience date <span style={{ color: 'red' }}> *</span>
                </Text>
                <DatePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  format="MM/DD/YYYY"
                  defaultValue={moment()}
                  disabledDate={disabledDate}
                  onChange={(date, dateString) => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        date: dateString,
                      },
                    }))
                  }}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Time <span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="time-select"
                  style={{ width: '100%' }}
                  placeholder="Choose a time"
                  onChange={e => {
                    document.getElementById('time-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                    document.getElementById('time-select-val').style.display = 'none'
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        time: e,
                      },
                    }))
                  }}
                >
                  <Option value={'07:00'}>7:00 AM</Option>
                  <Option value={'07:30'}>7:30 AM</Option>
                  <Option value={'08:00'}>8:00 AM</Option>
                  <Option value={'08:30'}>8:30 AM</Option>
                  <Option value={'09:00'}>9:00 AM</Option>
                  <Option value={'09:30'}>9:30 AM</Option>
                  <Option value={'10:00'}>10:00 AM</Option>
                  <Option value={'10:30'}>10:30 AM</Option>
                  <Option value={'11:00'}>11:00 AM</Option>
                  <Option value={'11:30'}>11:30 AM</Option>
                  <Option value={'12:00'}>12:00 PM</Option>
                  <Option value={'12:30'}>12:30 PM</Option>
                  <Option value={'13:00'}>1:00 PM</Option>
                  <Option value={'13:30'}>1:30 PM</Option>
                  <Option value={'14:00'}>2:00 PM</Option>
                  <Option value={'14:30'}>2:30 PM</Option>
                  <Option value={'15:00'}>3:00 PM</Option>
                  <Option value={'15:30'}>3:30 PM</Option>
                  <Option value={'16:00'}>4:00 PM</Option>
                  <Option value={'16:30'}>4:30 PM</Option>
                  <Option value={'17:00'}>5:00 PM</Option>
                  <Option value={'17:30'}>5:30 PM</Option>
                  <Option value={'18:00'}>6:00 PM</Option>
                  <Option value={'18:30'}>6:30 PM</Option>
                  <Option value={'19:00'}>7:00 PM</Option>
                  <Option value={'19:30'}>7:30 PM</Option>
                  <Option value={'20:00'}>8:00 PM</Option>
                  <Option value={'20:30'}>8:30 PM</Option>
                  <Option value={'21:00'}>9:00 PM</Option>
                  <Option value={'21:30'}>9:30 PM</Option>
                  <Option value={'22:00'}>10:00 PM</Option>
                </Select>
                <span id="time-select-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Experience type <span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="type-select"
                  style={{ width: '100%' }}
                  defaultValue={0}
                  placeholder="Please select a experience type"
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        type: e,
                      },
                    }))
                  }}
                >
                  <Option value={0}>Prepare</Option>
                  <Option value={1}>Teach</Option>
                </Select>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Does anyone have allergies? <span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="allergy-select"
                  style={{ width: '100%' }}
                  placeholder="Does anyone have allergies?"
                  defaultValue={false}
                  onChange={e => {
                    let isAlleries = e === true ? [] : null
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        isAllergy: e,
                        allergies: isAlleries,
                      },
                    }))
                  }}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
                <span id="allergy-select-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>
              {booking.isAllergy && (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Text strong={true}>
                      Allergies in party <span style={{ color: 'red' }}> *</span>
                    </Text>
                    <Select
                      id="allergies-select"
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Please select allergies party"
                      onChange={e => {
                        document.getElementById('allergies-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                        document.getElementById('allergies-select-val').style.display = 'none'
                        context.setState(prevState => ({
                          booking: {
                            ...prevState.booking,
                            allergies: e,
                          },
                        }))
                      }}
                    >
                      {alleriesOptions.map((item, idx) => (
                        <Option value={item.name} key={idx}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <span id="allergies-select-val" style={{ color: 'red', display: 'none' }}>
                      This field is required!
                    </span>
                  </Col>
                </>
              )}
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>Comments</Text>
                <TextArea
                  id="comment-input"
                  placeholder="Write"
                  autoSize
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        allergyNote: e.target.value,
                      },
                    }))
                  }}
                  rows={4}
                />
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Do you have any pets<span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="pets-select"
                  style={{ width: '100%' }}
                  placeholder="Please select a pets"
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        hasPets: e,
                      },
                    }))
                  }}
                  defaultValue={false}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>Add-ons</Text>
                <Select
                  id="addons-select"
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Please select add-ons"
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        addOnIds: e,
                      },
                    }))
                  }}
                >
                  {addonsOptions.map((item, idx) => (
                    <Option value={item.id} key={idx}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[12, 12]} className="mt-2">
              <Col xs={24} md={12} lg={8}>
                <Text strong={true}>
                  Is parking available for the chef at your Experience location ?<span style={{ color: 'red' }}> *</span>
                </Text>
                <Select
                  id="parking-select"
                  style={{ width: '100%' }}
                  placeholder="Please select parking status"
                  defaultValue={false}
                  onChange={e => {
                    context.setState(prevState => ({
                      booking: {
                        ...prevState.booking,
                        isParking: e,
                      },
                    }))
                  }}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Col>
              {booking.isParking && (
                <Col xs={24} md={12} lg={8}>
                  <Text strong={true}>
                    <span>Please specify parking details</span>
                  </Text>
                  <TextArea
                    id="comment-input"
                    placeholder="Write"
                    autoSize
                    onChange={e => {
                      context.setState(prevState => ({
                        booking: {
                          ...prevState.booking,
                          parkingNote: e.target.value,
                        },
                      }))
                    }}
                  />
                </Col>
              )}
            </Row>
            {taxRate === '' && <div style={{ color: 'red', fontWeight: 'bold' }}>This menu is not available in your area</div>}
            {booking.menuDetails && booking.longitude !== 0 && (
              <Spin spinning={menuLoadingSpin} tip="Loading ...">
                <Row gutter={[12, 12]} className="mt-4">
                  <Col xs={24}>
                    <h1 className="booking-header">Menu Details</h1>
                  </Col>
                </Row>
                <Row gutter={[12, 12]} className="mt-2">
                  <Col xs={24} md={12}>
                    <Row gutter={[20, 10]}>
                      <Col xs={8} md={6}>
                        <LazyImage src={booking.menuDetails.images[0]} alt="placeHolder" />
                      </Col>
                      <Col xs={16} md={18}>
                        <Row gutter={10}>
                          <Col xs={24}>
                            <Text strong={true}>Menu Name : </Text> {booking.menuDetails.name}
                          </Col>
                          {booking.addOnIds &&
                            addonsOptions
                              .filter(({ id }) => booking.addOnIds.includes(id))
                              .map((addOn, idx) => {
                                return (
                                  <Col xs={24} key={idx}>
                                    <Text strong={false} style={{ color: '#4e9a06bf' }}>
                                      {addOn.name} :
                                    </Text>{' '}
                                    {addOn.amount > 0 ? (
                                      <span style={{ color: '#4e9a06bf' }}>{currencyFormat(addOn.amount)}</span>
                                    ) : (
                                      <span style={{ color: 'red' }}>{currencyFormat(addOn.amount)}</span>
                                    )}
                                  </Col>
                                )
                              })}
                          {feeDetails?.holidaySurcharge && (
                            <Col xs={24} style={{ color: '#FF1414' }}>
                              <Text strong={false} style={{ color: '#FF1414' }}>
                                {feeDetails?.holidaySurcharge.name}
                                {feeDetails?.holidaySurcharge.percent && ' (%' + feeDetails?.holidaySurcharge.percent + ')'} :
                              </Text>
                              {feeDetails?.holidaySurcharge.amount > 0 ? (
                                currencyFormat(feeDetails?.holidaySurcharge.amount)
                              ) : (
                                <span style={{ color: 'red' }}> {currencyFormat(feeDetails?.holidaySurcharge.amount)}</span>
                              )}
                              {feeDetails?.holidaySurcharge.tooltip && <span>[ {feeDetails?.holidaySurcharge.tooltip} ]</span>}
                              <hr />
                            </Col>
                          )}
                          <Col xs={24}>
                            <Text strong={false}>Subtotal : </Text> {currencyFormat(feeDetails?.subtotal)}
                          </Col>
                          {/*{booking.servings < 4 && (
                            <Col xs={24}>
                              <Text strong={false}>Small Group Surcharge : </Text> {currencyFormat(this.getSmallGroup())}
                            </Col>
                          )} */}
                          {feeDetails.fees !== undefined &&
                            feeDetails?.fees.map((fee, idx) => {
                              return (
                                <Col xs={24} key={idx}>
                                  <Text strong={false}>
                                    {fee.name}
                                    {fee.percent && ' (%' + fee.percent + ')'} :
                                  </Text>{' '}
                                  {fee.amount > 0 ? currencyFormat(fee.amount) : <span style={{ color: 'red' }}>{currencyFormat(fee.amount)}</span>}
                                </Col>
                              )
                            })}

                          {/* {booking.giftDetails && (
                            <Col xs={24}>
                              <Text strong={false}>Coupon Code ({booking.giftDetails.code}) :</Text>
                              <span style={{ color: 'red' }}> -{currencyFormat(parseFloat(booking.giftDetails.remainingValue).toFixed(2))}</span>
                            </Col>
                          )}
                          {booking.couponDetails && (
                            <Col xs={24}>
                              <Text strong={false}>Coupon Code ({booking.couponDetails.id}) :</Text>

                              {booking.couponDetails.amount_off !== null && (
                                <span style={{ color: 'red' }}> -{currencyFormat(parseFloat(booking.couponDetails.amount_off / 100).toFixed(2))}</span>
                              )}
                              {booking.couponDetails.percent_off !== null && (
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  -{currencyFormat(parseFloat(this.getSubtotalPrice() / booking.couponDetails.percent_off).toFixed(2))}
                                </span>
                              )}
                            </Col>
                          )} */}
                          {/* <Col xs={24}>
                            <Text strong={false}>{booking.menuDetails?.tax.name} : </Text> {currencyFormat(this.getEstimatedTax())}
                          </Col> */}
                          {/* {taxRate !== 0 && (
                            <Col xs={24}>
                              {taxRate !== '' && (
                                <>
                                  <Text strong={false}>{taxRate?.name} :</Text> {currencyFormat(this.getEstimatedTax())}
                                </>
                              )}
                            </Col>
                          )} */}
                          <Col xs={24}>
                            <Text strong={true}>Total : </Text> {currencyFormat(feeDetails.total)}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row gutter={[20, 10]} className="mb-2">
                      <Col xs={18}>
                        <Input
                          placeholder="Gift Card"
                          onChange={e => {
                            this.setState({
                              giftCard: e.target.value,
                            })
                          }}
                        />
                        {feeDetails.giftCardError && <span style={{ color: 'red' }}>{feeDetails.giftCardError}</span>}
                      </Col>
                      <Col xs={6}>
                        <Button onClick={() => this.applyGiftCard(giftCard)} disabled={booking.menuDetails?.price > 0 ? false : true}>
                          Apply
                        </Button>
                      </Col>
                    </Row>
                    <Row gutter={[20, 10]} className="mb-2">
                      <Col xs={18}>
                        <Input
                          placeholder="Coupon Code"
                          onChange={e => {
                            this.setState({
                              couponCode: e.target.value,
                            })
                          }}
                        />
                        {feeDetails.couponError && <span style={{ color: 'red' }}>{feeDetails.couponError}</span>}
                      </Col>
                      <Col xs={6}>
                        <Button onClick={() => this.applyCoupon(couponCode)} disabled={booking.menuDetails?.price > 0 ? false : true}>
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Spin>
            )}
            <Row gutter={[20, 10]} className="mt-2 mb-2">
              <Col xs={24}>
                <Button
                  onClick={() => {
                    this.setState({
                      modalVisible: true,
                    })
                  }}
                  style={{ marginBottom: '1rem' }}
                  disabled={booking?.customerId ? false : true}
                >
                  <i className="kt-menu__ver-arrow la la-plus" />
                  Add Card
                </Button>
                {!booking?.customerId && <span style={{ color: 'red', fontWeight: 'bold', paddingLeft: 10 }}>We need customer</span>}
                <div id="card-input">
                  <Table
                    className="card-list-table"
                    dataSource={cardOptions}
                    bordered={true}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={record => record.cardId}
                    loading={this.state.loadingSpin}
                  />
                </div>
                <span id="card-input-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </span>
              </Col>
            </Row>
            <Row gutter={24} className="mt-2" justify="end">
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.submitBooking}
                  style={{ float: 'right' }}
                  loading={this.state.submitDisable}
                  disabled={this.state.submitDisable || this.state.taxRate === ''}
                >
                  Create booking
                </Button>
              </Col>
            </Row>
          </PortletBody>
        </Portlet>

        <ElementsConsumer>
          {({ stripe, elements }) => (
            <AddCardModal
              stripe={stripe}
              elements={elements}
              visible={modalVisible}
              hideModal={this.hideAddModal}
              refreshList={this.refreshList}
              customerId={booking?.customerId}
            />
          )}
        </ElementsConsumer>
      </Spin>
    )
  }
}
export default BookingCreate
