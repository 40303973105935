import React from 'react'
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid'
import { Row, Col, Input, Radio, Button, notification } from 'antd'
import configClient from '../api/config'
import httpStatus from 'http-status-codes'
import { Editor } from '@tinymce/tinymce-react'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import AddOns from './AddOns'
const tinyAPIKey = '2btxhsu7kectzuz2hbp2i8t9mrnm752pjo66yfcmyaecint6'
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
}
class ConfigList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: true,
      value: '',
      totalCount: 0,
      pageSize: 50,
      currentPage: 0,
      infoTitle: '',
      infoType: 0,
      infoValue: '',
    }
    this.onRowUpdating = this.onRowUpdating.bind(this)
    this.saveCustomMenu = this.saveCustomMenu.bind(this)
  }

  onRowUpdating(e) {
    if (e.oldData && e.newData) {
      configClient
        .update(e.oldData.key, e.newData.value)
        .then(response => {
          if (response.status === httpStatus.OK) {
            // success
          } else {
            // retrieve failed
            console.error(response)
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          // handle error
          console.error(error)
          this.setState({ loading: false })
        })
    }
  }

  componentDidMount() {
    // fetch tag list
    this.fetchCustomMenuInfo()
    const { pageSize, currentPage } = this.state
    if (currentPage === this.lastCurrentPage) {
      this.setState({ loading: false })
      return
    }
    const skip = pageSize * currentPage
    configClient
      .list({
        start: skip,
        length: pageSize,
        search: {
          value: '',
          regex: null,
        },
        columns: [
          {
            data: 'Value',
            value: null,
            searchable: true,
            orderable: true,
            search: {
              value: null,
              regex: null,
            },
          },
        ],
        orders: [
          {
            column: 0,
            direction: 0,
          },
        ],
      })
      .then(response => {
        if (response.status === httpStatus.OK) {
          const rows = response.data.items.map(item => {
            return {
              key: item.id,
              value: item.value,
              name: item.name,
            }
          })
          this.setState({
            rows: rows,
            totalCount: response.data.totalItems,
            loading: false,
          })
          this.lastCurrentPage = currentPage
        } else {
          // retrieve failed
          console.error(response)
          this.setState({ loading: false })
        }
      })
      .catch(error => {
        // handle error
        console.error(error)
        this.setState({ loading: false })
      })
  }

  fetchCustomMenuInfo() {
    configClient.getCustomMenuInfo().then(response => {
      if (response.status === httpStatus.OK && response.data) {
        this.setState({
          infoTitle: response.data.title,
          infoType: response.data.type,
          infoValue: response.data.value,
        })
      }
    })
  }

  saveCustomMenu() {
    const { infoTitle, infoType, infoValue } = this.state
    configClient
      .setCustomMenuInfo({
        title: infoTitle,
        type: infoType,
        value: infoValue,
      })
      .then(response => {
        if (response.status === httpStatus.OK) {
          notification.success({
            message: 'Custom menu info saved',
          })
        } else {
          notification.error({
            message: 'Something went wrong!',
          })
        }
      })
  }

  render() {
    const { rows, infoTitle, infoType, infoValue } = this.state
    return (
      <Row>
        <Col span={24}>
          <Portlet>
            <PortletHeader title="Configuration" />
            <PortletBody>
              <React.Fragment>
                <DataGrid
                  id={'gridContainer'}
                  dataSource={rows}
                  keyExpr={'key'}
                  allowColumnReordering={true}
                  showBorders={true}
                  showRowLines={true}
                  onEditingStart={this.onEditingStart}
                  onInitNewRow={this.onInitNewRow}
                  onRowInserting={this.onRowInserting}
                  onRowInserted={this.onRowInserted}
                  onRowUpdating={this.onRowUpdating}
                  onRowUpdated={this.onRowUpdated}
                  onRowRemoving={this.onRowRemoving}
                  onRowRemoved={this.onRowRemoved}
                >
                  <Paging enabled={true} />
                  <Editing mode={'row'} allowUpdating={true} allowDeleting={false} allowAdding={false} />
                  <Column dataField={'name'} caption={'Description'} allowEditing={false} />
                  <Column dataField={'value'} caption={'Value'} />
                </DataGrid>
              </React.Fragment>
            </PortletBody>
          </Portlet>
          <AddOns />
          <Portlet>
            <PortletHeader title="Custom Menu Info" />
            <PortletBody>
              <Row className="mb-3">
                <Col span={24}>Title:</Col>
                <Col span={24}>
                  <Input
                    placeholder="Custom info title"
                    style={{ maxWidth: '300px' }}
                    value={infoTitle}
                    onChange={e => {
                      this.setState({ infoTitle: e.target.value })
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={24}>Type:</Col>
                <Col span={24}>
                  <Radio.Group
                    onChange={e => {
                      this.setState({
                        infoType: e.target.value,
                      })
                    }}
                    value={infoType}
                  >
                    <Radio value={0} style={radioStyle}>
                      Link
                    </Radio>
                    <Radio value={1} style={radioStyle}>
                      HTML Content
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={24}>Value:</Col>
                <Col span={24}>
                  {infoType === 0 ? (
                    <Input
                      placeholder="Custom info value"
                      style={{ maxWidth: '300px' }}
                      value={infoValue}
                      onChange={e => {
                        this.setState({ infoValue: e.target.value })
                      }}
                    />
                  ) : (
                    <Editor
                      apiKey={tinyAPIKey}
                      initialValue={infoValue}
                      init={{
                        height: 400,
                        menubar: false,
                        plugins: [
                          'code advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste',
                        ],
                        toolbar: [
                          'code | bold italic backcolor | fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                        ],
                      }}
                      onEditorChange={e => {
                        this.setState({ infoValue: e })
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={24}>
                  <Button type="primary" onClick={this.saveCustomMenu}>
                    Save
                  </Button>
                </Col>
              </Row>
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
    )
  }
}

export default ConfigList
