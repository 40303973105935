import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import 'react-block-ui/style.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

class ConversationItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.selectConversation = this.selectConversation.bind(this);
    }
    selectConversation() {
        this.props.selectConversation(this.props.id)
    }
    render() {
        const { participants, text, date } = this.props;
        const formattedDate = moment(date).format('MM/DD/YYYY')
        return (
            <>
                <Row className={this.props.className} onClick={this.selectConversation}>
                    <Col flex="auto">
                        <Row>
                            <Col span={24}>
                                <span style={{ color: '#678fde', fontWeight: 500 }}>{participants}</span>
                            </Col>
                            <Col span={24}>
                                {text}
                            </Col>
                        </Row>
                    </Col>
                    <Col flex="100px">
                        <div className="text-right" style={{ color: '#777' }}>
                            {formattedDate}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ConversationItem;
