import ApiClient from './client';
const client = new ApiClient();

export default {
    list(paginationInfo) {
        return client.post('/api/Account/ConversationListPaginated', paginationInfo, {
            'Content-Type': 'application/json'
        });
    },

    details(conversationId) {
        return client.get(`/api/Account/Conversation/${conversationId}`);
    }
}   