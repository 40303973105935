import React from 'react'
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid'
import timePeriodClient from '../api/timeperiod'
import httpStatus from 'http-status-codes'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '../styles/table.scss'

class TimePeriod extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: true,
      value: '',
      totalCount: 0,
      pageSize: 50,
      currentPage: 0,
    }
    this.onRowInserting = this.onRowInserting.bind(this)
    this.onRowUpdating = this.onRowUpdating.bind(this)
    this.onRowRemoving = this.onRowRemoving.bind(this)
  }

  onRowRemoving(e) {
    if (e.data) {
      timePeriodClient
        .delete(e.data.id, e.data.name)
        .then(response => {
          if (response.status === httpStatus.OK) {
            // success
          } else {
            // retrieve failed
            console.error(response)
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          // handle error
          console.error(error)
          this.setState({ loading: false })
        })
    }
  }

  onRowInserting(e) {
    if (e.data) {
      timePeriodClient
        .create(e.data.name)
        .then(response => {
          if (response.status === httpStatus.OK) {
            // success
          } else {
            // retrieve failed
            console.error(response)
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          // handle error
          console.error(error)
          this.setState({ loading: false })
        })
    }
  }

  onRowUpdating(e) {
    if (e.oldData && e.newData) {
      timePeriodClient
        .update(e.oldData.id, e.newData.name)
        .then(response => {
          if (response.status === httpStatus.OK) {
            // success
          } else {
            // retrieve failed
            console.error(response)
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          // handle error
          console.error(error)
          this.setState({ loading: false })
        })
    }
  }

  componentDidMount() {
    // fetch list
    const { value, pageSize, currentPage } = this.state
    if (currentPage === this.lastCurrentPage) {
      this.setState({ loading: false })
      return
    }
    const skip = pageSize * currentPage
    timePeriodClient
      .list({
        start: skip,
        length: pageSize,
        search: {
          value: value,
          regex: null,
        },
        columns: [
          {
            data: 'Name',
            name: null,
            searchable: true,
            orderable: true,
            search: {
              value: null,
              regex: null,
            },
          },
        ],
        orders: [
          {
            column: 0,
            direction: 0,
          },
        ],
      })
      .then(response => {
        if (response.status === httpStatus.OK) {
          // successfully retrieved
          const rows = response.data.items.map(item => {
            return {
              id: item.id,
              name: item.name,
            }
          })
          this.setState({
            rows: rows,
            totalCount: response.data.totalItems,
            loading: false,
          })
          this.lastCurrentPage = currentPage
        } else {
          // retrieve failed
          console.error(response)
          this.setState({ loading: false })
        }
      })
      .catch(error => {
        // handle error
        console.error(error)
        this.setState({ loading: false })
      })
  }

  render() {
    const { rows } = this.state
    return (
      <Portlet fluidHeight={true}>
        <PortletHeader title="Time Period" />
        <PortletBody>
          <React.Fragment>
            <DataGrid
              id={'gridContainer'}
              dataSource={rows}
              keyExpr={'id'}
              allowColumnReordering={true}
              showBorders={true}
              showRowLines={true}
              onEditingStart={this.onEditingStart}
              onInitNewRow={this.onInitNewRow}
              onRowInserting={this.onRowInserting}
              onRowInserted={this.onRowInserted}
              onRowUpdating={this.onRowUpdating}
              onRowUpdated={this.onRowUpdated}
              onRowRemoving={this.onRowRemoving}
              onRowRemoved={this.onRowRemoved}
            >
              <Paging enabled={true} />
              <Editing mode={'row'} allowUpdating={true} allowDeleting={true} allowAdding={true} />
              <Column dataField={'name'} caption={'Name'} />
            </DataGrid>
          </React.Fragment>
        </PortletBody>
      </Portlet>
    )
  }
}

export default TimePeriod
