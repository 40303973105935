import ApiClient from './client'
const client = new ApiClient()

export default {
  checkCustomer(customer) {
    return client.get(`/api/Account/ListCustomer/${customer}`, {
      'Content-Type': 'application/json',
    })
  },
  listShortMenu(chefId) {
    return client.get(`/api/Menu/ListSHortMenu/${chefId}`, {
      'Content-Type': 'application/json',
    })
  },
}
