import React, { useCallback, useState, useEffect } from 'react'
import { Form, Col, Row, Table, Button, notification, Modal, Input, InputNumber, Select } from 'antd'

import { confirmOperation } from '../../components/Helper-functions'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import apiClient from '../api/homeScreenCategory'
const { Option } = Select
export default function HomeScreenCategory() {
  const [form] = Form.useForm()
  const [categoryFormRef] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [cityList, setCityList] = useState([])
  const [editingKey, setEditingKey] = useState('')
  const [visible, setVisible] = useState(false)
  const isEditing = record => record.id === editingKey

  const edit = record => {
    form.setFieldValue('name', record.name)
    setEditingKey(record.id)
  }

  const fetchCityList = useCallback(() => {
    setLoading(true)
    apiClient
      .cityList()
      .then(response => {
        if (response.status === 200) {
          setCityList(response.data)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])
  const fetchCategories = useCallback(() => {
    setLoading(true)
    apiClient
      .list({
        start: 0,
        length: 1000,
        search: {
          value: '',
          regex: null,
        },
        columns: [
          {
            data: 'Name',
            name: null,
            searchable: true,
            orderable: true,
            search: {
              value: null,
              regex: null,
            },
          },
        ],
        orders: [
          {
            column: 0,
            direction: 0,
          },
        ],
      })
      .then(response => {
        if (response.status === 200) {
          const items = response.data.items.sort((a, b) => (a.id < b.id ? 1 : -1))
          setCategories(items)
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])
  useEffect(() => {
    fetchCityList()
    fetchCategories()
    // eslint-disable-next-line
  }, [])

  const handleSave = async key => {
    try {
      const row = await form.validateFields()
      const newData = [...categories]
      const index = newData.findIndex(item => key === item.id)
      if (index > -1) {
        setLoading(true)
        apiClient
          .edit(key, row)
          .then(res => {
            if (res.status === 200) {
              fetchCategories()
              setEditingKey('')
              notification.success({ message: 'Category has been successfully updated!' })
            }
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false)
          })
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const handleDelete = id => {
    apiClient
      .delete(id)
      .then(res => {
        if (res.status === 200) {
          notification.success({ message: 'Category has been successfully deleted!' })
          fetchCategories()
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }
  const handleSubmit = () => {
    categoryFormRef
      .validateFields()
      .then(values => {
        setLoading(true)
        apiClient
          .create(values.name, values.cityId)
          .then(res => {
            if (res.status === 200) {
              notification.success({ message: 'New category has been successfully added!' })
              setVisible(false)
              fetchCategories()
              categoryFormRef.resetFields()
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false))
      })
      .catch(() => {
        categoryFormRef.resetFields()
      })
      .finally(() => {
        setLoading(false)
        setVisible(false)
        categoryFormRef.resetFields()
      })
  }
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center',
      width: '5%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '50%',
    },
    {
      title: 'City',
      dataIndex: 'cityName',
      width: '20%',
    },
    {
      title: 'Actions',
      align: 'center',
      width: '25%',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <div className="middle-centered">
              <Button
                type="primary"
                className=" mr-1"
                onClick={() =>
                  confirmOperation({
                    content: 'Want to save your changes ?',
                    okText: 'Yes',
                    onOk: () => handleSave(record.id),
                    onCancel: () => setEditingKey(''),
                  })
                }
              >
                Save
              </Button>
              <Button type="secondary" className="" onClick={() => setEditingKey('')}>
                Cancel
              </Button>
            </div>
          </span>
        ) : (
          <div className="middle-centered">
            <Button type="primary" className="mr-1" disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Button>
            <Button
              type="danger"
              className=""
              disabled={editingKey !== ''}
              onClick={() =>
                confirmOperation({
                  content: 'Do you want to delete this category?',
                  okText: 'Yes',
                  onOk: () => handleDelete(record.id),
                })
              }
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'version' ? 'number' : 'text',
        autoFocus: col.dataIndex === 'name',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <>
      <Portlet fluidHeight={true}>
        <PortletHeader title="Home Screen Categories" />
        <PortletBody>
          <div>
            <Button style={{ marginBottom: '1rem', textAlign: 'left' }} onClick={() => setVisible(true)}>
              <i className="kt-menu__ver-arrow la la-plus" />
              Add Category
            </Button>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={categories}
                loading={loading}
                columns={mergedColumns}
                rowClassName="editable-row"
                rowKey={row => row.id}
              />
            </Form>
          </div>
          <Modal
            visible={visible}
            onCancel={() => {
              setVisible(false)
              categoryFormRef.resetFields()
            }}
            footer={[
              <Col span={24} key={1}>
                <Button form="add-new-category-form" htmlType="submit" type="primary" className="w-full">
                  Add category
                </Button>
              </Col>,
            ]}
            title="Create new category"
            centered
            width={360}
          >
            <Form form={categoryFormRef} name="add-new-category-form" layout="vertical" onFinish={values => handleSubmit(values)}>
              <Row gutter={[10, 10]} align="top">
                <Col xs={24}>
                  <Form.Item
                    name="cityId"
                    label="City"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Please select city!',
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      showSearch
                      allowClear={false}
                      className="w-100"
                      placeholder="Select a city"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {cityList.length > 0
                        ? cityList.map(function(el) {
                            return (
                              <Option value={el.id} key={el.id}>
                                {el.name}
                              </Option>
                            )
                          })
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="name"
                    label="Category Name"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter category name!',
                      },
                    ]}
                  >
                    <Input placeholder="Category Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </PortletBody>
      </Portlet>
    </>
  )
}

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, autoFocus, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber autoFocus={autoFocus} /> : <Input autoFocus={autoFocus} />
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}
