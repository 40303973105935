/**
 * Entry application component used to compose providers and render Routes.
 *
 * Note: Because of
 */

import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from './app/Routes'
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from './_metronic'

/** TEST STRIPE API */
//const stripePromise = loadStripe('pk_test_5KutisDbozt5fHLYrBiGGQwS00uXXyc2M7')

/** LIVE STRIPE API */
const stripePromise = loadStripe('pk_live_pT6R5CR5wHrSfw5afkVmcdvr00AXLuwpvi');

const CARD_FIELDS_STYLE = {
  fonts: [
    {
      family: 'MuseoSansRounded500',
      src: "url('//db.onlinewebfonts.com/t/a07bcad627607b6777616f8e058e8917.ttf')",
    },
  ],
}

export default function App({ store, Layout, persistor, basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/* Provide Metronic theme overrides. */}
            <ThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Elements stripe={stripePromise} options={CARD_FIELDS_STYLE}>
                  <Routes Layout={Layout} />
                </Elements>
              </I18nProvider>
            </ThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  )
}
