import ApiClient from './client';
const client = new ApiClient();

export default {
    list(paginationInfo) {
        return client.post('api/menu/UnitsListPaginated', paginationInfo, {
            'Content-Type': 'application/json'
        });
    },

    listNoPagination() {
        return client.get('api/menu/ListUnits', {
            'Content-Type': 'application/json'
        });
    },

    create(name) {
        return client.post('api/menu/UnitAdd', {
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    update(id, name) {
        return client.post('api/menu/UnitUpdate', {
            'id': id,
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    delete(id) {
        return client.delete('api/menu/UnitDelete/' + id, {
            'Content-Type': 'application/json'
        });
    }
}   