import ApiClient from './client'
const client = new ApiClient()

export default {
  list(paginationInfo) {
    return client.post('/api/Booking/BookingListPaginated', paginationInfo, {
      'Content-Type': 'application/json',
    })
  },

  advancedList(api, paginationInfo) {
    return client.post(api, paginationInfo, {
      'Content-Type': 'application/json',
    })
  },

  details(bookingId) {
    return client.get('/api/Booking/BookingDetail/' + bookingId, {
      'Content-Type': 'application/json',
    })
  },

  advancedDetails(bookingId) {
    return client.get('/api/Booking/' + bookingId, {
      'Content-Type': 'application/json',
    })
  },

  updateBooking(bookingId, bookingData) {
    return client.put('/api/Booking/UpdateBookingUpcoming/' + bookingId, bookingData, {
      'Content-Type': 'application/json',
    })
  },
  allergiesList() {
    return client.get(`/api/Booking/ListAllergies`, {
      'Content-Type': 'application/json',
    })
  },
  menuDetails(menuId, currentTime) {
    return client.post(`/api/Menu/MenuDetailNew/${menuId}`, currentTime, {
      'Content-Type': 'application/json',
    })
  },
  giftCard(code) {
    return client.get(`/api/Booking/Coupon/${code}`, {
      'Content-Type': 'application/json',
    })
  },
  couponCode(code, amount) {
    return client.post(`/api/Booking/Coupon/Verify`, code, amount, {
      'Content-Type': 'application/json',
    })
  },
  cardList(customerId) {
    return client.get(`/api/Account/CardList/${customerId}`, {
      'Content-Type': 'application/json',
    })
  },
  addCard(customerId, token, isPrimary) {
    return client.post(`/api/Account/CardAdd/${customerId}`, token, isPrimary, {
      'Content-Type': 'application/json',
    })
  },
  createBooking(customerId, bookingInfo) {
    return client.post(`/api/Booking/BookingAdd/${customerId}`, bookingInfo, {
      'Content-Type': 'application/json',
    })
  },
  taxRate(zipCode) {
    return client.get(`/api/Booking/TaxRate/${zipCode}`, {
      'Content-Type': 'application/json',
    })
  },
  bookingReview(customerId, reviewData) {
    return client.post(`/api/Booking/BookingReview/${customerId}`, reviewData, {
      'Content-Type': 'application/json',
    })
  },
}
