import ApiClient from './client';
const client = new ApiClient();

export default {

    preferenceList(paginationInfo) {
        return client.post('/api/Common/PreferenceListPaginated', paginationInfo, {
            'Content-Type': 'application/json'
        });
    },

    restrictionList() {
        return client.get('/api/Menu/ListRestrictions', {
            'Content-Type': 'application/json'
        });
    },

    preferenceListNoPagination() {
        return client.get('/api/Menu/ListPreferences', {
            'Content-Type': 'application/json'
        });
    },

    restrictionListNoPagination() {
        return client.get('/api/Menu/ListRestrictions', {
            'Content-Type': 'application/json'
        });
    },

    preferenceCreate(name) {
        return client.post('/api/Common/PreferenceAdd', {
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    restrictionCreate(item) {
        return client.post('/api/Common/RestrictionAdd', item, {
            'Content-Type': 'application/json'
        });
    },

    dietaryDelete(id) {
        return client.delete('/api/Common/DietaryDelete/' + id, {
            'Content-Type': 'application/json'
        });
    },
}   