import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Button, Popconfirm, Modal, Input, Col, Row, notification, Spin, Select, Form } from 'antd'

import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import cityClient from '../api/city'
import { toAbsoluteUrl } from '../../../_metronic'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '../styles/table.scss'
const { Option } = Select

const CityList = () => {
  const myRef = useRef()
  const [cityFormRef] = Form.useForm()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [zipVisible, setZipVisible] = useState(false)
  const [cityDetails, setCityDetails] = useState()
  const [defaultId, setDefaultId] = useState(0)
  const [zipModalText, setZipModalText] = useState('')
  const [zips, setZips] = useState([])
  const [zipFile, setZipFile] = useState('')
  const fetchCityList = useCallback(() => {
    setLoading(true)
    cityClient
      .list()
      .then(response => {
        if (response.status === 200) {
          const rows = response.data.map(item => {
            return {
              id: item.id,
              name: item.name,
            }
          })
          setCityDetails(response.data)
          setRows(rows)
          setLoading(false)
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const deleteCity = id => {
    if (id > 0) {
      cityClient
        .delete(id)
        .then(response => {
          if (response.status === 200) {
            setTimeout(function() {
              fetchCityList()
            }, 500)
          } else {
            setLoading(false)
          }
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }

  const deleteZip = id => {
    if (id > 0) {
      cityClient
        .deleteZips(id)
        .then(response => {
          if (response.status === 200) {
            setTimeout(function() {
              fetchCityList()
            }, 500)
          } else {
            setLoading(false)
          }
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }

  const handleOkModalAddCity = () => {
    setLoading(true)
    cityFormRef.validateFields().then(values => {
      cityClient
        .create({ ...values })
        .then(response => {
          if (response.status === 200 || response.status === 204) {
            setVisible(false)
            fetchCityList()
            setLoading(false)
            notification.success({
              message: 'Successfully added the city!',
            })
          }
        })
        .catch(error => {
          setLoading(false)
          setVisible(false)
          console.log(error)
        })
    })
  }
  const cityEdit = () => {
    setLoading(true)
    cityFormRef.validateFields().then(values => {
      const data = {
        ...values,
        id: defaultId,
        taxRate: Number(values.taxRate),
      }
      cityClient
        .edit({ ...data })
        .then(response => {
          if (response.status === 200) {
            setVisible(false)
            fetchCityList()
            setLoading(false)
            notification.success({
              message: 'Successfully updated the city details!',
            })
          }
        })
        .catch(error => {
          setLoading(false)
          setVisible(false)
          console.log(error)
        })
    })
  }
  const handleValidation = () => {
    var resultArray = []
    if (!zipModalText) {
      document.getElementById('zip-input').style.border = '1px solid red'
      document.getElementById('zip-input').style.borderRadius = '5px'
      document.getElementById('zip-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    return resultArray.length === 0
  }
  const handleOkModalAddZip = () => {
    if (handleValidation()) {
      setLoading(true)
      if (zipModalText.length > 0) {
        cityClient.checkZip(zipModalText).then(res => {
          if (res.status === 200) {
            notification.error({
              message: `This ${zipModalText} has already been registered! (${res.data})`,
            })
            setLoading(false)
          }
          if (res.status === 204) {
            cityClient.addZip(defaultId, zipModalText).then(res => {
              if (res.status === 200) {
                setZipVisible(false)
                setZipModalText('')
                fetchCityZip(defaultId)
                setLoading(false)
                notification.success({
                  message: 'Successfully added the zip!',
                })
              }
            })
          }
        })
      }
    }
  }
  const fetchCityZip = useCallback(cityId => {
    setLoading(true)
    cityClient.zipList(cityId).then(res => {
      if (res.status === 200) {
        setZips(res.data)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (defaultId) {
      fetchCityZip(defaultId)
    }
  }, [defaultId, fetchCityZip])
  function callback(key) {
    setTimeout(() => {
      setDefaultId(key)
    }, 1000)
    setLoading(true)
  }
  const removeZipCode = zip => {
    cityClient.deleteZip(defaultId, zip).then(res => {
      if (res.status === 200) {
        notification.success({
          message: 'Successfully deleted the zip!',
        })
        setTimeout(function() {
          fetchCityZip(defaultId)
        }, 500)
      }
    })
  }

  const handleOnChangeFile = e => {
    setZipFile(e.target.files[0])
  }
  const handleOnSubmitFile = e => {
    e.preventDefault()
    if (zipFile) {
      let fileReader = new FileReader()
      fileReader.onload = async event => {
        const text = event.target.result
        await csvFileToArray(text)
      }

      fileReader.readAsText(zipFile)
    }
  }

  const csvFileToArray = string => {
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\r\n')
    if (csvRows) {
      setLoading(true)
      let newArr = csvRows.map((item, index, arr) => {
        return Number(item)
      })
      if (newArr.some(isNaN)) {
        setLoading(false)
        notification.error({
          message: 'Something went wrong, please try again.',
          description: `We couldn't upload ${zipFile.name}`,
        })
        myRef.current.value = ''
      } else {
        setLoading(true)
        newArr.map(zip =>
          cityClient.checkZip(zip).then(res => {
            if (res.status === 200) {
              notification.error({
                message: `This ${zip} has already been registered! (${res.data})`,
              })
              setLoading(false)
              myRef.current.value = ''
            }
            if (res.status === 204) {
              cityClient
                .addZip(defaultId, zip)
                .then(res => {
                  if (res.status === 200) {
                    myRef.current.value = ''
                    setTimeout(function() {
                      fetchCityZip(defaultId)
                    }, 1000)
                    setLoading(false)
                  }
                })
                .catch(err => {
                  console.error(err)
                })
            }
          }),
        )
      }
    }
  }

  useEffect(() => {
    fetchCityList()
  }, [fetchCityList])
  const cityInfo = cityDetails?.filter(city => city.id === defaultId)
  return (
    <Portlet fluidHeight={true}>
      <PortletHeader title="Cities" />
      <PortletBody>
        <div>
          <Button
            onClick={() => {
              setVisible(true)
              setDefaultId(0)
            }}
            style={{ marginBottom: '1rem' }}
          >
            <i className="kt-menu__ver-arrow la la-plus" />
            Add City
          </Button>
          <div>City name:</div>
          <Select
            showSearch
            allowClear={false}
            style={{ width: 320, marginBottom: '1rem' }}
            onChange={async value => {
              callback(value)
            }}
            placeholder="Select a city"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {rows.length > 0
              ? rows.map(function(el) {
                  return (
                    <Option value={el.id} key={el.id}>
                      {el.name}
                    </Option>
                  )
                })
              : ''}
          </Select>
          {defaultId > 0 && (
            <>
              <Button
                onClick={() => {
                  setVisible(true)
                  var city = cityDetails.find(item => item.id === defaultId)
                  cityFormRef.setFieldsValue({
                    ...city,
                  })
                  setDefaultId(city?.id)
                }}
                style={{ marginBottom: '1rem' }}
              >
                <i className="kt-menu__ver-arrow la la-edit" />
                Edit City
              </Button>
              <Button
                onClick={() => {
                  setZipVisible(true)
                }}
                style={{ marginBottom: '1rem' }}
              >
                <i className="kt-menu__ver-arrow la la-plus" />
                Add Zip
              </Button>
              <Row gutter={[20]}>
                <Col className="mb-2">
                  Example CSV:{' '}
                  <a aria-label={'CSV'} href={toAbsoluteUrl('/media/csv/Example.csv')} aria-hidden="true">
                    HERE
                  </a>
                </Col>
              </Row>
              <Row gutter={0} style={{ marginBottom: '1.5rem' }}>
                <Col xs={20}>
                  <input
                    accept=".csv"
                    id="csvFileInput"
                    multiple
                    type="file"
                    className="form-control"
                    onChange={e => handleOnChangeFile(e)}
                    ref={myRef}
                    style={{ height: 'auto' }}
                  />
                </Col>
                <Col xs={3} className="w-100">
                  <Button
                    variant="contained"
                    component="span"
                    className="w-100"
                    style={{ height: '100%' }}
                    onClick={e => {
                      handleOnSubmitFile(e)
                    }}
                    disabled={zipFile === ''}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <Spin spinning={loading} tip="Loading ...">
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col xs={24}>
                    Tax Rate : <b>{cityInfo[0].taxRate}</b>
                  </Col>
                  <Col xs={24}>
                    Mail Chimp : <b>{cityInfo[0].mailChimpId ? cityInfo[0].mailChimpId : 'N/A'}</b>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col xs={24} style={{ maxHeight: 150, overflow: 'hidden', overflowY: 'auto' }}>
                    {zips.length > 0 ? (
                      zips.map((zip, idx) => (
                        <Popconfirm
                          placement="top"
                          title="Are you sure to remove this zipcode ?"
                          onConfirm={() => removeZipCode(zip)}
                          okText="Yes"
                          key={idx}
                          cancelText="No"
                        >
                          <button type="button" className="btn btn-secondary btn-pill btn-zipcode" key={zip}>
                            {zip}
                          </button>
                        </Popconfirm>
                      ))
                    ) : (
                      <div className="text-center text-gray2 py-2">You have no zip list</div>
                    )}
                  </Col>
                </Row>
              </Spin>
              <Popconfirm
                title="Are you sure delete all zip?"
                okText="Yes"
                cancelText="No"
                onConfirm={e => {
                  deleteZip(defaultId)
                }}
              >
                <Button className={`courseType-delete mr-2`} type="danger" style={{ float: 'right' }}>
                  <i className="fa fa-trash-alt mr-2" />
                  Delete Zips
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={e => {
                  deleteCity(defaultId)
                }}
              >
                <Button className={`courseType-delete mr-2`} type="danger" style={{ float: 'right' }}>
                  <i className="fa fa-trash-alt mr-2" />
                  Delete City
                </Button>
              </Popconfirm>
            </>
          )}

          <Modal
            title={defaultId ? 'Edit city' : 'Add new city'}
            visible={visible}
            okText="Submit"
            onCancel={() => {
              setVisible(false)
              cityFormRef.resetFields()
            }}
            footer={[
              <Row gutter={[10, 0]} key="modal_footer" justify="center">
                <Col span={24}>
                  <Button form="create_city" htmlType="submit" loading={loading}>
                    {defaultId ? 'Save changes' : 'Add City'}
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Form name="create_city" form={cityFormRef} layout="vertical" onFinish={defaultId ? cityEdit : handleOkModalAddCity}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your city name!' }]}>
                <Input placeholder="City name" allowClear />
              </Form.Item>
              <Form.Item label="Tax rate" name="taxRate" rules={[{ required: true, message: 'Please input your city tax rate!' }]}>
                <Input
                  placeholder="City tax rate"
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </Form.Item>
              <Form.Item label="Mailchimp" name="mailChimpId" rules={[{ required: false, message: 'Please input your city mailchimp!' }]}>
                <Input placeholder="City mailchimp" allowClear />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Add new zip"
            visible={zipVisible}
            onOk={handleOkModalAddZip}
            okText="Submit"
            onCancel={() => {
              setZipVisible(false)
              setZipModalText('')
            }}
          >
            <div id="zip-input">
              <Input
                placeholder="Zip"
                allowClear
                value={zipModalText}
                onChange={e => setZipModalText(e.target.value)}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
              />
            </div>
            <span id="zip-input-val" style={{ color: 'red', display: 'none' }}>
              This field is required!
            </span>
          </Modal>
        </div>
      </PortletBody>
    </Portlet>
  )
}
export default CityList
