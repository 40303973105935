import React from 'react'
import httpStatus from 'http-status-codes'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import { Input, Select, Button, Row, Col, InputNumber, Typography, Collapse, Popconfirm, Upload, Modal, DatePicker, Tag, Spin, notification } from 'antd'
import { PlusOutlined, CalendarOutlined, MinusCircleOutlined } from '@ant-design/icons'
import courseTypeClient from '../api/courseType'
import accountClient from '../api/account'
import categoryClient from '../api/category'
import tagClient from '../api/tags'
import menuClient from '../api/menu'
import unitClient from '../api/units'
import dietaryClient from '../api/dietary'

import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '../styles/table.scss'
import '../styles/menuDetails.scss'
import moment from 'moment'

const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography

class MenuCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: {
        id: 0,
      },
      ingredientsList: [],

      preferenceList: [],
      restrictionList: [],
      availabilityList: [],

      chefId: 0,
      categoryId: 0,
      tagIds: [],
      preferenceIds: [],
      restrictionIds: [],

      visibleCourseDetails: false,
      courseList: [],

      previewVisible: false,
      previewImage: '',
      fileList: [],
      imageUploadList: [],

      newAvailabilityDate: moment().format('MM/DD/YYYY'),
      newIngredientUnitId: '',
      newIngredientUnitName: '',
      newIngredientUnitSize: '',

      loadingSpin: true,

      chefOptions: [],
      categoryOptions: [],
      tagOptions: [],
      unitOptions: [],
      preferenceOptions: [],
      restrictionOptions: [],
      courseTypeOptions: [],
    }
    //#region Bind functions
    this.handleValidation = this.handleValidation.bind(this)
    this.fetchChefList = this.fetchChefList.bind(this)
    this.fetchCategoryList = this.fetchCategoryList.bind(this)
    this.fetchTagList = this.fetchTagList.bind(this)
    this.fetchUnitList = this.fetchUnitList.bind(this)
    this.fetchPreferenceList = this.fetchPreferenceList.bind(this)
    this.fetchRestrictionList = this.fetchRestrictionList.bind(this)
    this.addCourse = this.addCourse.bind(this)
    this.addCourseItem = this.addCourseItem.bind(this)
    this.removeCourse = this.removeCourse.bind(this)
    this.getBase64 = this.getBase64.bind(this)
    this.handleImageCancel = this.handleImageCancel.bind(this)
    this.handleImagePreview = this.handleImagePreview.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.removeIngredient = this.removeIngredient.bind(this)

    this.changeIngredName = this.changeIngredName.bind(this)
    this.changeIngredSize = this.changeIngredSize.bind(this)
    this.changeIngredUnit = this.changeIngredUnit.bind(this)
    this.changeCourseItemName = this.changeCourseItemName.bind(this)

    this.submitMenuChanges = this.submitMenuChanges.bind(this)
    //#endregion
  }

  async componentDidMount() {
    this.setState({
      menu: {
        id: 0,
        name: '',
        categoryId: 0,
        description: '',
        type: 0,
        estimatedPrice: 0,
        chefId: 0,
        tags: [],
        dietaries: [],
        images: [],
        courses: [],
        ingredients: [],
        availabilitys: [],
      },
    })
    await this.fetchChefList()
    await this.fetchCategoryList()
    await this.fetchTagList()
    await this.fetchUnitList()
    await this.fetchPreferenceList()
    await this.fetchRestrictionList()
    await this.fetchCourseTypeList()
    this.setState({
      loadingSpin: false,
    })
  }
  //#region API List Calls
  async fetchChefList() {
    await accountClient.listChef().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          chefOptions: res.data,
        })
      }
    })
  }
  async fetchCategoryList() {
    await categoryClient.listNoPagination().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          categoryOptions: res.data,
        })
      }
    })
  }
  async fetchTagList() {
    await tagClient.listNoPagination().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          tagOptions: res.data,
        })
      }
    })
  }
  async fetchUnitList() {
    await unitClient.listNoPagination().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          unitOptions: res.data,
        })
      }
    })
  }
  async fetchPreferenceList() {
    await dietaryClient.preferenceListNoPagination().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          preferenceOptions: res.data,
        })
      }
    })
  }
  async fetchRestrictionList() {
    await dietaryClient.restrictionListNoPagination().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          restrictionOptions: res.data,
        })
      }
    })
  }
  async fetchCourseTypeList() {
    await courseTypeClient.list().then(res => {
      if (res.status === httpStatus.OK) {
        this.setState({
          courseTypeOptions: res.data,
        })
      }
    })
  }
  //#endregion

  removeCourse(courseId) {
    let tempCourseList = this.state.courseList.filter(item => item.id !== courseId)
    this.setState({
      courseList: tempCourseList,
    })
  }
  removeCourseItem(courseId, courseItemId) {
    let tempList = this.state.courseList
    this.setState({
      courseList: tempList.map(el => {
        if (el.id === courseId) el.courseItems = el.courseItems.filter(x => x.id !== courseItemId)
        return el
      }),
    })
  }
  addCourse() {
    let courseList = this.state.courseList
    let lastId =
      courseList
        .map(x => x.id)
        .reduce((a, b) => {
          return Math.max(a, b)
        }, 0) + 1
    let lastPriorityId =
      courseList
        .map(x => x.priority)
        .reduce((a, b) => {
          return Math.max(a, b)
        }, 0) + 1
    courseList.push({
      id: lastId,
      name: 'New Course',
      priority: lastPriorityId,
      courseTypeId: 0,
      courseItems: [],
    })
    this.setState({
      courseList: courseList,
    })
  }
  addCourseItem(courseId) {
    let tempCourseList = this.state.courseList
    var courseItemList = []
    tempCourseList.map(item => {
      if (item.id === courseId) courseItemList = item.courseItems
      return item
    })
    courseItemList.sort((a, b) => {
      return a.id - b.id
    })
    var lastKey = 1
    if (courseItemList.length > 0) {
      lastKey = courseItemList[courseItemList.length - 1].id + 1
    }
    courseItemList.push({
      id: lastKey,
      name: '',
      description: '',
    })
    tempCourseList.map(item => {
      if (item.id === courseId) item.courseItems = courseItemList
      return item
    })
    this.setState({
      courseList: tempCourseList,
    })
  }
  renameCourseName(e, element) {
    let courseList = this.state.courseList.map(el => {
      if (el.id === element.id) {
        el.name = e.target.value
      }
      return el
    })
    this.setState({ courseList })
  }
  handleValidation() {
    var resultArray = []
    const { imageUploadList, menu, courseList } = this.state
    if (imageUploadList.length === 0) {
      document.getElementsByClassName('ant-upload-select')[0].style.borderColor = 'red'
      document.getElementById('image-upload-val').style.display = 'block'
      resultArray.push(false)
    }
    if (!menu.name) {
      document.getElementById('name-input').style.borderColor = 'red'
      document.getElementById('name-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!menu.description) {
      document.getElementById('desc-input').style.borderColor = 'red'
      document.getElementById('desc-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!menu.chefId) {
      document.getElementById('chef-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('chef-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (!menu.categoryId) {
      document.getElementById('cat-select').closest('.ant-select-selector').style.borderColor = 'red'
      document.getElementById('cat-select-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    if (courseList.length > 0) {
      courseList.forEach(course => {
        if (course.courseTypeId === 0) {
          const courseId = `course-${course.id}`
          document.getElementById(courseId).closest('.ant-select-selector').style.borderColor = 'red'
          resultArray.push(false)
        }
      })
    }
    return resultArray.length === 0
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  handleImageCancel() {
    this.setState({
      previewVisible: false,
    })
  }
  async handleImagePreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    })
  }
  async handleImageChange(fileList) {
    var imageUploadList = this.state.imageUploadList
    if (fileList.file.status === 'done') {
      var imageContent = await this.getBase64(fileList.file.originFileObj)
      imageUploadList.push({
        type: 0,
        url: '',
        file: {
          content: imageContent.substr(imageContent.indexOf(',') + 1),
          name: fileList.file.name,
          contentType: fileList.file.type,
        },
      })
      document.getElementsByClassName('ant-upload-select')[0].style.borderColor = '#d9d9d9'
      document.getElementById('image-upload-val').style.display = 'none'
      this.setState({
        imageUploadList,
      })
    } else if (fileList.file.status === 'removed') {
      imageUploadList = fileList.fileList.map(function(el) {
        if (el.url !== undefined) {
          return {
            type: 1,
            url: el.url,
            file: null,
          }
        } else {
          const imageContent = el.thumbUrl
          return {
            type: 0,
            url: '',
            file: {
              content: imageContent.substr(imageContent.indexOf(',') + 1),
              name: el.name,
              contentType: el.type,
            },
          }
        }
      })
      this.setState({
        imageUploadList,
      })
    }
    this.setState({
      fileList: fileList.fileList,
    })
  }
  removeIngredient(ingredientId) {
    let tempList = this.state.ingredientsList
    tempList = tempList.filter(function(item) {
      return !(item.id === ingredientId)
    })
    this.setState({
      ingredientsList: tempList,
    })
  }
  changeIngredName(id, e) {
    var ingredientsList = this.state.ingredientsList
    ingredientsList.map(function(el) {
      if (el.id === id) {
        el.name = e.target.value
        return el
      } else return el
    })
    this.setState({ ingredientsList })
  }
  changeIngredSize(id, e) {
    var ingredientsList = this.state.ingredientsList
    ingredientsList.map(function(el) {
      if (el.id === id) {
        el.size = e.target.value
        return el
      } else return el
    })
    this.setState({ ingredientsList })
  }
  changeIngredUnit(id, e) {
    var ingredientsList = this.state.ingredientsList
    ingredientsList.map(function(el) {
      if (el.id === id) {
        el.unit.id = e
        return el
      } else return el
    })
    this.setState({ ingredientsList })
  }
  submitMenuChanges() {
    const result = this.handleValidation()
    if (result) {
      var menu = this.state.menu
      const ingredientList =
        this.state.ingredientsList.length > 0
          ? this.state.ingredientsList.map(el => {
              return {
                name: el.name,
                unitId: el.unit.id,
                size: el.size,
              }
            })
          : []
      const dietaryList = []
      this.state.preferenceList.forEach(el => {
        dietaryList.push(el.id)
      })
      this.state.restrictionList.forEach(el => {
        dietaryList.push(el.id)
      })
      var availabilitysList = this.state.availabilityList.map(el => {
        return {
          availableDate: el,
          timePeriodId: 1,
        }
      })
      var courses = this.state.courseList
      courses.forEach(element => {
        delete element.id
        element.courseItems.forEach(courseItem => {
          delete courseItem.id
        })
      })
      var menuInfo = {
        id: menu.id,
        name: menu.name,
        categoryId: menu.categoryId,
        description: menu.description,
        type: menu.type,
        estimatedPrice: menu.price === undefined ? 0 : menu.price,
        chefId: menu.chefId,
        tags: menu.tags,
        dietaries: dietaryList,
        images: this.state.imageUploadList,
        courses: courses,
        ingredients: ingredientList,
        availabilitys: availabilitysList,
      }
      menuClient.create(menuInfo).then(res => {
        if (res.status === httpStatus.OK) {
          notification.success({
            message: 'Menu Saved',
            description: 'Menu has been successfully created!',
          })
          this.setState({
            loadingSpin: true,
          })
          const context = this
          setTimeout(function() {
            context.props.history.push({
              pathname: '/menu',
            })
          }, 1500)
          menuClient.pendingCount().then(res => {
            if (res.status === httpStatus.OK) {
              document.getElementsByClassName('customElementPendingMenu')[0].innerText = res.data
            }
          })
        }
      })
    }
  }
  changeCourseItemName(courseId, id, e) {
    var courseList = this.state.courseList
    courseList.forEach(function(course) {
      if (course.id === courseId) {
        course.courseItems.forEach(function(courseItem) {
          if (courseItem.id === id) {
            courseItem.name = e.target.value
          }
        })
      }
    })
    this.setState({ courseList })
  }
  changeCourseItemDesc(courseId, id, e) {
    var courseList = this.state.courseList
    courseList.forEach(function(course) {
      if (course.id === courseId) {
        course.courseItems.forEach(function(courseItem) {
          if (courseItem.id === id) {
            courseItem.description = e.target.value
          }
        })
      }
    })
    this.setState({ courseList })
  }
  render() {
    const {
      chefOptions,
      categoryOptions,
      tagOptions,
      unitOptions,
      preferenceOptions,
      restrictionOptions,
      courseTypeOptions,
      previewVisible,
      previewImage,
      fileList,
      loadingSpin,
    } = this.state
    var context = this
    const removeIcon = courseId => (
      <Popconfirm
        title="Are you sure delete this course?"
        onConfirm={e => {
          context.removeCourse(courseId)
        }}
        okText="Yes"
        cancelText="No"
      >
        <MinusCircleOutlined className="mr-2" style={{ color: '#fd397a' }} />
      </Popconfirm>
    )
    const courseItemList = (element, courseId) =>
      element.courseItems.map(function(el, idx) {
        return (
          <Row gutter={24} style={{ marginBottom: '0.5rem' }} key={idx}>
            <Col span={10}>
              <TextArea
                placeholder="Item name"
                defaultValue={el.name}
                key={'itemname_' + el.id}
                autoSize
                onChange={e => context.changeCourseItemName(courseId, el.id, e)}
              />
            </Col>
            <Col span={12}>
              <TextArea
                placeholder="Item description"
                defaultValue={el.description}
                key={'itemdesc_' + el.id}
                autoSize
                onChange={e => context.changeCourseItemDesc(courseId, el.id, e)}
              />
            </Col>
            <Col span={2}>
              <Popconfirm
                title="Are you sure delete this course item?"
                onConfirm={() => context.removeCourseItem(courseId, el.id)}
                okText="Yes"
                cancelText="No"
              >
                <MinusCircleOutlined className="mr-2" style={{ color: '#fd397a' }} />
              </Popconfirm>
            </Col>
          </Row>
        )
      })
    const courseItems = this.state.courseList.map(function(course) {
      const courseId = `course-${course.id}`
      return (
        <Panel header={course.name && course.name} key={course.id} extra={removeIcon(course.id)}>
          <div className="mb-3">
            <Text strong={true}>Course Name</Text>
            <Input
              placeholder="Course name"
              defaultValue={course.name}
              key={course.id}
              onChange={e => {
                let courseList = context.state.courseList.map(el => {
                  if (el.id === course.id) {
                    el.name = e.target.value
                  }
                  return el
                })
                context.setState({
                  courseList: courseList,
                })
              }}
            />
          </div>
          <div className="mb-3">
            <Text strong={true}>Course Type</Text>
            <Select
              id={courseId}
              showSearch
              allowClear={false}
              style={{ width: '100%' }}
              onChange={e => {
                var courseList = context.state.courseList.map(el => {
                  if (el.id === course.id) {
                    el.courseTypeId = e
                  }
                  return el
                })
                context.setState({
                  courseList: courseList,
                })
              }}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Please select a course type"
              // defaultValue={courseTypeOptions[0].name}
            >
              {courseTypeOptions.map((item, idx) => {
                return (
                  <Option value={item.id} key={idx}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className="mb-3">
            <Text strong={true}>Course List Order</Text>
            <div>
              <InputNumber
                min={1}
                className="mr-3"
                placeholder="Order"
                defaultValue={course.priority}
                key={course.id}
                onChange={e => {
                  var courseList = context.state.courseList
                  courseList.map(el => {
                    if (el.id === course.id) {
                      if (isNaN(e) || !e) {
                        el.priority = 0
                      } else el.priority = parseInt(e)
                    }
                    return null
                  })
                  context.setState({
                    courseList: courseList,
                  })
                }}
              />
            </div>
          </div>
          <div>
            <Text strong={true}>
              <div style={{ width: '100%' }}>Course Items</div>
            </Text>
            <Row gutter={24}>
              <Col span={10}>
                <Text>Name</Text>
              </Col>
              <Col span={14}>
                <Text>Description</Text>
              </Col>
            </Row>
          </div>
          {courseItemList(course, course.id)}
          <Button
            type="dashed"
            onClick={e => {
              context.addCourseItem(course.id)
            }}
            style={{ width: '180px', float: 'right', marginBottom: '1rem', marginTop: '0.5rem' }}
          >
            <PlusOutlined /> Add Course Item
          </Button>
        </Panel>
      )
    })
    const ingredientsItems = this.state.ingredientsList.map((el, index) => {
      return (
        <Row gutter={24} style={{ marginBottom: '1rem' }} key={index}>
          <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Popconfirm
              title="Are you sure delete this ingredient?"
              onConfirm={e => {
                context.removeIngredient(el.id)
              }}
              okText="Yes"
              cancelText="No"
            >
              <MinusCircleOutlined className="mr-2" style={{ color: '#fd397a' }} />
            </Popconfirm>
            <Input
              placeholder="Ingredient"
              style={{ marginRight: '9px', flexGrow: '1', width: 'initial' }}
              onChange={e => context.changeIngredName(el.id, e)}
            />
            <Input placeholder="Size" style={{ flexBasis: '70px', marginRight: '9px' }} onChange={e => context.changeIngredSize(el.id, e)} />
            <Select
              showSearch
              allowClear={false}
              style={{ flexBasis: '130px' }}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder="Unit"
              onChange={e => context.changeIngredUnit(el.id, e)}
            >
              {unitOptions.map((item, idx) => {
                return (
                  <Option value={item.id} key={idx}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
        </Row>
      )
    })
    const availabilitiesOptions = this.state.availabilityList.map((el, idx) => {
      return (
        <Tag
          key={idx}
          closable
          onClose={e => {
            e.preventDefault()
            context.setState({ availabilityList: context.state.availabilityList.filter(date => date !== el) })
          }}
          style={{
            padding: '5px 12px',
            display: 'inline-flex',
            alignItems: 'center',
            marginTop: '5px',
          }}
        >
          <CalendarOutlined className="mr-3" />
          <Text style={{ fontWeight: '400' }}>{el}</Text>
        </Tag>
      )
    })

    return (
      <Spin spinning={loadingSpin}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Create Menu" />
          <PortletBody>
            <div style={{ marginBottom: '20px' }}>
              <Button
                onClick={() => {
                  this.props.history.push({
                    pathname: '/menu',
                    state: {
                      prevMenuListPagination:
                        context.props.history.location.state && context.props.history.location.state.prevMenuListPagination
                          ? context.props.history.location.state.prevMenuListPagination
                          : null,
                      prevChefId:
                        context.props.history.location.state && context.props.history.location.state.prevChefId
                          ? context.props.history.location.state.prevChefId
                          : null,
                    },
                  })
                }}
              >
                <i className="kt-menu__ver-arrow la la-angle-left" />
                Back to Menu List
              </Button>
            </div>
            <Row gutter={24}>
              <Col span={12}>
                <Text strong={true}>
                  Menu Images
                  <span style={{ color: 'red' }}> *</span>
                </Text>
                <div id="image-upload-val" style={{ color: 'red', display: 'none' }}>
                  This field is required!
                </div>
                <Upload
                  id="image-upload"
                  customRequest={({ file, onSuccess, onError }) => {
                    setTimeout(() => {
                      onSuccess('ok', file)
                    }, 100)
                  }}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handleImagePreview}
                  onChange={this.handleImageChange}
                >
                  {fileList.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleImageCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col>
                    <Text strong={true}>
                      Menu Name<span style={{ color: 'red' }}> *</span>
                    </Text>
                    <Input
                      id="name-input"
                      placeholder="Menu name"
                      onChange={e => {
                        const val = e.target.value
                        if (val) {
                          document.getElementById('name-input').style.borderColor = '#d9d9d9'
                          document.getElementById('name-input-val').style.display = 'none'
                        }
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            name: val,
                          },
                        }))
                      }}
                    />
                    <span id="name-input-val" style={{ color: 'red', display: 'none' }}>
                      This field is required!
                    </span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col span={24}>
                    <Text strong={true}>
                      Menu Description<span style={{ color: 'red' }}> *</span>
                    </Text>
                    <TextArea
                      id="desc-input"
                      placeholder="Please enter menu description!"
                      autoSize
                      onChange={e => {
                        const val = e.target.value
                        if (val) {
                          document.getElementById('desc-input').style.borderColor = '#d9d9d9'
                          document.getElementById('desc-input-val').style.display = 'none'
                        }
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            description: val,
                          },
                        }))
                      }}
                    />
                    <span id="desc-input-val" style={{ color: 'red', display: 'none' }}>
                      This field is required!
                    </span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col span={24}>
                    <Text strong={true}>
                      Chef<span style={{ color: 'red' }}> *</span>
                    </Text>
                    <Select
                      id="chef-select"
                      showSearch
                      allowClear={false}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Please select a chef"
                      onChange={e => {
                        document.getElementById('chef-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                        document.getElementById('chef-select-val').style.display = 'none'
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            chefId: e,
                          },
                        }))
                      }}
                    >
                      {chefOptions.map((item, idx) => (
                        <Option value={item.id} key={idx}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <span id="chef-select-val" style={{ color: 'red', display: 'none' }}>
                      This field is required!
                    </span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col>
                    <Text strong={true}>
                      Menu Category<span style={{ color: 'red' }}> *</span>
                    </Text>
                    <Select
                      id="cat-select"
                      showSearch
                      allowClear={false}
                      style={{ width: '100%' }}
                      onChange={e => {
                        document.getElementById('cat-select').closest('.ant-select-selector').style.borderColor = '#d9d9d9'
                        document.getElementById('cat-select-val').style.display = 'none'
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            categoryId: e,
                          },
                        }))
                      }}
                      optionFilterProp="children"
                      placeholder="Please select a category"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {categoryOptions.map((item, idx) => (
                        <Option value={item.id} key={idx}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <span id="cat-select-val" style={{ color: 'red', display: 'none' }}>
                      This field is required!
                    </span>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col xs={24}>
                    <Text strong={true}>Menu Tags</Text>
                    <Select
                      showSearch
                      allowClear={false}
                      mode="multiple"
                      style={{ width: '100%' }}
                      onChange={e => {
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            tags: e,
                          },
                        }))
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Please select a tag"
                    >
                      {tagOptions.map((item, idx) => {
                        return (
                          <Option value={item.id} key={idx}>
                            {item.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col span={12}>
                    <Text strong={true}>Preferences</Text>
                    <Select
                      showSearch
                      allowClear={false}
                      mode="multiple"
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Please select a preference"
                      onChange={e => {
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            preferenceList: e.map(function(el) {
                              return {
                                id: el,
                                name: '',
                              }
                            }),
                          },
                        }))
                      }}
                    >
                      {preferenceOptions.map((item, idx) => (
                        <Option value={item.id} key={idx}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <Text strong={true}>Restrictions</Text>
                    <Select
                      showSearch
                      allowClear={false}
                      mode="multiple"
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Please select a restriction"
                      onChange={e => {
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            restrictionList: e.map(el => {
                              return {
                                id: el,
                                name: '',
                              }
                            }),
                          },
                        }))
                      }}
                    >
                      {restrictionOptions.map((item, idx) => {
                        return (
                          <Option value={item.id} key={idx}>
                            {item.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col>
                    <Text strong={true}>
                      <div>Ingredients</div>
                    </Text>
                    {ingredientsItems}
                    <Button
                      type="dashed"
                      style={{ width: '150px', marginTop: '0.5rem', float: 'right' }}
                      onClick={() => {
                        let ingredientsList = context.state.ingredientsList
                        ingredientsList.sort(function(a, b) {
                          return a.id - b.id
                        })
                        let lastKey = 1
                        if (ingredientsList.length > 0) {
                          lastKey = ingredientsList[ingredientsList.length - 1].id + 1
                        }
                        let unit = {
                          id: null,
                        }
                        ingredientsList.push({
                          id: lastKey,
                          name: '',
                          size: '',
                          unit: unit,
                        })
                        context.setState({
                          ingredientsList,
                        })
                      }}
                    >
                      <PlusOutlined /> Add Ingredient
                    </Button>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col>
                    <Text strong={true}>
                      <div>Availability</div>
                    </Text>
                    <DatePicker
                      allowClear={false}
                      format="MM/DD/YYYY"
                      defaultValue={moment()}
                      onChange={(date, dateString) => {
                        context.setState({ newAvailabilityDate: dateString })
                      }}
                    />
                    <Button
                      style={{ marginTop: '0.5rem', marginLeft: '8px' }}
                      onClick={() => {
                        var availabilityList = context.state.availabilityList
                        if (availabilityList.indexOf(context.state.newAvailabilityDate) === -1) {
                          availabilityList.push(context.state.newAvailabilityDate)
                          context.setState({ availabilityList })
                        }
                      }}
                    >
                      <PlusOutlined /> Add Date
                    </Button>
                    <div>{availabilitiesOptions}</div>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                  <Col>
                    <Text strong={true}>
                      <div>Price</div>
                    </Text>
                    <InputNumber
                      prefix="$"
                      suffix="USD"
                      min={0}
                      max={100000}
                      defaultValue={0}
                      onChange={e => {
                        context.setState(prevState => ({
                          menu: {
                            ...prevState.menu,
                            price: e,
                          },
                        }))
                      }}
                    />
                    <span style={{ marginLeft: '5px' }}>USD</span>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Button type="primary" htmlType="submit" onClick={this.submitMenuChanges}>
                      Create Menu
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Text strong={true}>Course List</Text>
                <Collapse>{courseItems}</Collapse>
                <Button type="dashed" onClick={() => this.addCourse()} style={{ width: '100%', marginTop: '0.5rem' }}>
                  <PlusOutlined /> Add Course
                </Button>
              </Col>
            </Row>
          </PortletBody>
        </Portlet>
      </Spin>
    )
  }
}

export default MenuCreate
