import React from 'react'
import { Button, Row, Col, Select, Table, Input } from 'antd'
import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
const { Option } = Select
class HomeMenuList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuList: [],
      allMenu: [],
      selectedMenuId: undefined,
      selectedMenuName: '',
    }
    this.callbackFunc = this.callbackFunc.bind(this)
  }

  async componentDidMount() {
    const props = this.props
    await this.setState({
      menuList: props.menuList,
      allMenu: props.allMenu,
    })
  }

  callbackFunc() {
    this.props.parentCallback(this.state.menuList)
  }

  render() {
    const { selectedMenuId, selectedMenuName, menuList, allMenu } = this.state
    const context = this
    const columns = [
      {
        title: 'Order',
        dataIndex: 'order',
        width: 50,
        render: function(order, row) {
          return (
            <Input
              value={order}
              onChange={async e => {
                var orderInt = parseInt(e.target.value)
                if (!orderInt) {
                  orderInt = 0
                }
                let menuItem = row
                menuItem.order = orderInt
                await context.setState({
                  menuList: menuList.map(el => (el.menuId === row.menuId ? menuItem : el)),
                })
                context.callbackFunc()
              }}
            />
          )
        },
      },
      {
        title: 'Menu',
        dataIndex: 'menuName',
      },
      {
        title: 'Action',
        dataIndex: 'menuId',
        width: 100,
        render: function(menuId) {
          return (
            <Button
              type="danger"
              onClick={async () => {
                let filteredArray = menuList.filter(x => x.menuId !== menuId)
                await context.setState({ menuList: filteredArray })
                context.callbackFunc()
              }}
            >
              Remove
            </Button>
          )
        },
      },
    ]
    return (
      <div>
        <Row>
          <Col flex="auto">
            <Select
              showSearch
              placeholder="Select a menu"
              optionFilterProp="children"
              style={{ width: '100%' }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={selectedMenuId}
              onChange={async (value, event) => {
                await this.setState({
                  selectedMenuId: value,
                  selectedMenuName: event.children,
                })
              }}
            >
              {allMenu.map(function(el) {
                return (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col flex="100px">
            <Button
              type="primary"
              className="ml-3"
              onClick={async () => {
                if (selectedMenuId) {
                  await this.setState({
                    selectedMenuId: undefined,
                    selectedMenuName: '',
                    menuList: [
                      ...menuList,
                      {
                        menuId: selectedMenuId,
                        menuName: selectedMenuName,
                        order: 0,
                      },
                    ],
                  })
                  this.callbackFunc()
                }
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Table
          className="mt-3"
          dataSource={menuList}
          bordered={true}
          columns={columns}
          rowKey={record => record.menuId}
          pagination={false}
          style={{ color: '#111!important' }}
        />
      </div>
    )
  }
}

export default HomeMenuList
