/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import httpStatus from 'http-status-codes'
import apiClient from '../api/courseType'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import 'antd/dist/antd.css'
import { Button, Table, Popconfirm, Modal, Input } from 'antd'
import '../styles/table.scss'

class CourseType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      loading: true,
      totalCount: 0,
      modalVisible: false,
      modalText: '',
    }
    this.fetchCourseTypeList = this.fetchCourseTypeList.bind(this)
    this.showModalAddCourseType = this.showModalAddCourseType.bind(this)
    this.handleCancelModalAddCourseType = this.handleCancelModalAddCourseType.bind(this)
    this.handleModalTextField = this.handleModalTextField.bind(this)
    this.handleOkModalAddCourseType = this.handleOkModalAddCourseType.bind(this)
    this.showModalAddCourseType = this.showModalAddCourseType.bind(this)
    this.showModalAddCourseType = this.showModalAddCourseType.bind(this)
  }

  componentDidMount() {
    this.fetchCourseTypeList()
  }

  fetchCourseTypeList() {
    apiClient
      .list({
        start: 0,
        length: 1000,
        search: {
          value: '',
          regex: null,
        },
        columns: [
          {
            data: 'Name',
            name: null,
            searchable: true,
            orderable: true,
            search: {
              value: null,
              regex: null,
            },
          },
        ],
        orders: [
          {
            column: 0,
            direction: 0,
          },
        ],
      })
      .then(response => {
        if (response.status === httpStatus.OK) {
          const rows = response.data.map(item => {
            return {
              id: item.id,
              name: item.name,
            }
          })
          this.setState({
            rows: rows,
            totalCount: response.data.length,
            loading: false,
          })
        } else {
          console.error(response)
          this.setState({ loading: false })
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({ loading: false })
      })
  }

  deleteCourseType(id, obj) {
    if (id > 0) {
      apiClient
        .delete(id)
        .then(response => {
          if (response.status === httpStatus.OK) {
            this.fetchCourseTypeList()
          } else {
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          this.setState({ loading: false })
        })
    }
  }

  showModalAddCourseType() {
    this.setState({
      modalVisible: true,
    })
  }

  handleCancelModalAddCourseType() {
    this.setState({
      modalVisible: false,
      modalText: '',
    })
  }

  handleModalTextField(event) {
    this.setState({
      modalText: event.target.value,
    })
  }

  handleOkModalAddCourseType() {
    var text = this.state.modalText
    if (text.length > 0) {
      apiClient
        .create(text)
        .then(response => {
          if (response.status === httpStatus.OK) {
            this.handleCancelModalAddCourseType()
            this.fetchCourseTypeList()
          } else {
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  render() {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Action',
        width: '130px',
        render: (text, record) => (
          <>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={e => {
                this.deleteCourseType(record.id, record)
              }}
            >
              <Button className={`courseType-delete mr-2`} type="danger">
                <i className="fa fa-trash-alt mr-2" />
                Delete
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ]
    const info = () => 'Total records: ' + this.state.totalCount
    const { modalVisible, modalText } = this.state
    return (
      <div>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Course types" />
          <PortletBody>
            <div>
              <Button onClick={this.showModalAddCourseType} style={{ marginBottom: '1rem' }}>
                <i className="kt-menu__ver-arrow la la-plus" />
                Add new course type
              </Button>
              <Table
                className="courseType-list-table"
                dataSource={this.state.rows}
                bordered={true}
                columns={columns}
                rowKey={record => record.id}
                loading={this.state.loading}
                footer={info}
                style={{
                  width: '500px',
                }}
              />
              <Modal
                title="New course type"
                visible={modalVisible}
                onOk={this.handleOkModalAddCourseType}
                okText="Submit"
                onCancel={this.handleCancelModalAddCourseType}
              >
                <Input placeholder="Course type" allowClear value={modalText} onChange={this.handleModalTextField} />
              </Modal>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    )
  }
}

export default CourseType
