import ApiClient from './client'
const client = new ApiClient()

export default {
  list(paginationInfo) {
    return client.post('/api/Account/AllergiesListPaginated', paginationInfo, {
      'Content-Type': 'application/json',
    })
  },

  create(name) {
    return client.post(
      'api/Account/AllergyAdd',
      {
        name: name,
      },
      {
        'Content-Type': 'application/json',
      },
    )
  },

  update(id, name) {
    return client.post(
      'api/Account/AllergyUpdate',
      {
        id: id,
        name: name,
      },
      {
        'Content-Type': 'application/json',
      },
    )
  },

  delete(id) {
    return client.delete('api/Account/AllergyDelete/' + id, {
      'Content-Type': 'application/json',
    })
  },
}
