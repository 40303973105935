import React, { useCallback, useEffect, useState } from 'react'
import { Button, Table, Modal, Row, Col, Form, Input, DatePicker, InputNumber, Switch, notification, Tag } from 'antd'
import moment from 'moment'

import holidayClient from '../api/holidays'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import { confirmOperation } from '../../components/Helper-functions'
export default function Holidays() {
  const [formRef] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [holidays, setHolidays] = useState({
    rows: [],
    totalCount: 0,
  })

  const fetchHolidayList = useCallback(() => {
    setLoading(true)
    holidayClient
      .list(initialPagination)
      .then(res => {
        if (res.status === 200) {
          setHolidays({
            rows: res.data.items,
            totalCount: res.data.totalItems,
          })
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [])
  useEffect(() => {
    fetchHolidayList()
    // eslint-disable-next-line
  }, [])

  const handleSubmit = () => {
    formRef.validateFields().then(values => {
      setLoading(true)
      holidayClient
        .create({ ...values })
        .then(res => {
          if (res.status === 200) {
            notification.success({ message: 'New holiday has been successfully added!' })
            fetchHolidayList()
            formRef.resetFields()
            setVisible(false)
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false)
        })
    })
  }
  const handleDelete = id => {
    setLoading(true)
    holidayClient
      .delete(id)
      .then(res => {
        if (res.status === 200) {
          notification.success({ message: 'Holiday has successfully removed!' })
          fetchHolidayList()
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }
  const handleSave = data => {
    setEdit(true)
    setVisible(true)
    formRef.setFieldValue('name', data.name)
    formRef.setFieldValue('tooltip', data.tooltip)
    formRef.setFieldValue('amount', data.amount)
    formRef.setFieldValue('date', moment(data.date))
    formRef.setFieldValue('isActive', data.isActive)
    setEditId(data.id)
  }
  const handleEdit = () => {
    formRef.validateFields().then(values => {
      setLoading(true)
      holidayClient
        .edit(editId, values)
        .then(res => {
          if (res.status === 200) {
            notification.success({ message: 'Holiday has been successfully updated!' })
            fetchHolidayList()
            setVisible(false)
            setEdit(false)
            formRef.resetFields()
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false))
    })
  }
  const columns = [
    { title: 'Name', dataIndex: 'name', align: 'left' },
    { title: 'Tooltip', dataIndex: 'tooltip' },
    { title: 'Amount', dataIndex: 'amount', align: 'center' },
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      render: date => {
        return moment(date).format('MM/DD/YYYY')
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      render: record => (record ? <Tag color="success">Active</Tag> : <Tag color="warning">Inactive</Tag>),
    },
    {
      title: 'Action',
      width: '13%',
      align: 'center',
      render: (_, record) => (
        <>
          <Row gutter={15}>
            <Col xs={12}>
              <Button
                onClick={() => {
                  handleSave(record)
                }}
                type="primary"
              >
                <i className="fa fa-edit mr-2" /> Edit
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                type="danger"
                onClick={() =>
                  confirmOperation({
                    content: 'Are your sure delete ?',
                    okText: 'Yes',
                    onOk: () => handleDelete(record.id),
                  })
                }
              >
                Delete
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
  ]
  return (
    <div>
      <Portlet fluidHeight={true}>
        <PortletHeader title="Holidays" />
        <PortletBody>
          <div>
            <Button style={{ marginBottom: '1rem' }} onClick={() => setVisible(true)}>
              <i className="kt-menu__ver-arrow la la-plus" />
              Add Holiday
            </Button>
            <Table className="holiday-list-table" dataSource={holidays.rows} bordered={true} columns={columns} rowKey={record => record.id} loading={loading} />
          </div>
        </PortletBody>
      </Portlet>
      <Modal
        title={edit ? 'Edit Holiday' : 'Add Holiday'}
        visible={visible}
        onCancel={() => {
          setVisible(false)
          setEdit(false)
          formRef.resetFields()
        }}
        centered
        footer={[
          <Row gutter={20} key={1}>
            <Col span={12} offset={12}>
              <Button type="primary" form="add_holiday" htmlType="submit">
                {edit ? 'Save' : 'Add'}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form id="add_holiday" layout="vertical" form={formRef} onFinish={() => (edit ? handleEdit() : handleSubmit())}>
          <Row gutter={[15, 15]}>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Name"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Please select name!',
                  },
                ]}
              >
                <Input placeholder="Holiday Name" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="tooltip" label="Tooltip" labelAlign="left">
                <Input.TextArea rows={3} placeholder="Holiday Tooltip" allowClear></Input.TextArea>
              </Form.Item>
              <Form.Item
                name="amount"
                label="Amount"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Please select amount!',
                  },
                ]}
              >
                <InputNumber placeholder="Holiday Amount" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="date"
                label="Date"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Please select date!',
                  },
                ]}
              >
                <DatePicker placeholder="Holiday Date" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="isActive" label="Status" labelAlign="left" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
const initialPagination = {
  start: 0,
  length: 1000,
  search: {
    value: '',
    regex: null,
  },
  columns: [
    {
      data: 'Name',
      name: null,
      searchable: true,
      orderable: true,
      search: {
        value: null,
        regex: null,
      },
    },
  ],
  orders: [
    {
      column: 0,
      direction: 0,
    },
  ],
}
