import React from 'react'
import axios from 'axios'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import { Input, Button, Row, Col, InputNumber, Typography, Upload, Spin, Rate, notification, message, Popconfirm, Modal, Alert } from 'antd'
import { LoadingOutlined, PlusCircleOutlined, UserOutlined, MailOutlined, PhoneOutlined, PushpinOutlined } from '@ant-design/icons'
import InputMask from 'react-input-mask'
import LocationSearchInput from '../../components/LocationSearchInput'
import MapComponent from '../../components/Map'
import chefClient from '../api/account'

import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '../styles/table.scss'
import '../styles/chefDetails.scss'

const { TextArea } = Input
const { Text } = Typography

class ChefDetails extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      loadingSpin: true,
      menuLoad: false,

      chefId: 0,
      firstName: '',
      lastName: '',
      email: '',
      contactEmail: '',
      phone: '',
      biography: '',
      radius: 0,
      rate: 0,
      image: {
        type: 0,
        url: '',
        file: {
          content: '',
          name: '',
          contentType: '',
        },
      },

      address: '',
      latitude: 0,
      longitude: 0,
      polygons: [],
      availableZips: [],
      zips: [],
      zipCodes: [],
      usedZipCodes: [],
      errorAddress: false,
      visible: false,
      isAddressChanged: false,

      zip: '',
      validation: [true],
      zipFile: '',
      zipArray: [],
    }
    this.fetchChefDetails = this.fetchChefDetails.bind(this)
    this.submitChefChanges = this.submitChefChanges.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleRateChange = this.handleRateChange.bind(this)
    this.csvFileToArray = this.csvFileToArray.bind(this)
    this.getBase64 = this.getBase64.bind(this)
    this.beforeImageUpload = this.beforeImageUpload.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleRadius = this.handleRadius.bind(this)
    this.handleOkModalAddZip = this.handleOkModalAddZip.bind(this)
  }

  componentDidMount() {
    if (this.props.location.state !== null && this.props.location.state !== undefined) {
      this.setState({
        chefId: this.props.location.state.chefId,
      })
      if (this.props.location.state.chefId > 0) {
        this.fetchChefDetails(this.props.location.state.chefId)
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAddressChanged) {
      if (prevState.zipCodes !== this.state.zipCodes) {
        this.setState({
          availableZips: this.state.zipCodes,
        })
      }
    }
  }
  fetchChefDetails(chefId) {
    chefClient
      .details(chefId)
      .then(response => {
        if (response.status === 200) {
          var image = {
            type: 1,
            url: response.data.imageUrl,
            file: {
              content: '',
              name: '',
              contentType: '',
            },
          }
          this.setState({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            contactEmail: response.data.contactEmail,
            phone: response.data.phone,
            biography: response.data.biography,
            address: response.data.address,
            radius: response.data.radius,
            rate: response.data.rate,
            image: image,
            latitude: Number(response.data.latitude),
            longitude: Number(response.data.longitude),
            loadingSpin: false,
            menuLoad: true,
            zips: response.data.zips,
            availableZips: response.data.zips,
            isAddressChanged: response.data.isAddressChanged,
          })
          if (this.state.latitude && this.state.longitude) {
            this.searchZipCode(this.state.radius, this.state.latitude, this.state.longitude)
          }
        } else {
          this.setState({ loadingSpin: false })
          console.error(response)
        }
      })
      .catch(error => {
        this.setState({ loadingSpin: false })
        console.error(error)
      })
  }

  handleAddress(addr, lat, lng) {
    this.setState({
      address: addr,
      latitude: lat,
      longitude: lng,
      errorAddress: false,
    })
  }

  handleRateChange(e) {
    const rateStr = e !== null ? e.toString() : 0
    if (!isNaN(rateStr[rateStr.length - 1])) {
      this.setState({
        rate: e,
      })
    }
  }

  async handleImageChange(info) {
    if (info.file.status === 'done') {
      var imageContent = await this.getBase64(info.file.originFileObj)
      var image = {
        type: 0,
        url: '',
        file: {
          content: imageContent.substr(imageContent.indexOf(',') + 1),
          name: info.file.name,
          contentType: info.file.type,
        },
      }
      this.setState({
        image: image,
      })
    }
  }

  beforeImageUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  getBase64(img) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(img)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  async searchZipCode(radius, latitude, longitude) {
    this.setState({ loadingSpin: true })
    if (latitude && longitude) {
      await axios({
        // url:
        //   'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/location?radius=' +
        //   radius +
        //   '&latitude=' +
        //   latitude +
        //   '&longitude=' +
        //   longitude,
        url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/zipcode/location',
        params: { latitude: latitude, longitude: longitude, radius: radius },
        method: 'get',
        headers: {
          'X-RapidAPI-Host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
          'X-RapidAPI-Key': '50dd6f6f04msh065ffab2e0fd2d5p1a86adjsnb1bd857ca0ab',
        },
      })
        .then(async response => {
          this.setState({ loadingSpin: true })
          if (response.status === 200) {
            this.setState({ loadingSpin: false })
            let polygons = []
            let zipCodes = []
            await response.data.features.map(region => {
              let used = this.state.usedZipCodes.indexOf(region.properties.zipCode) === -1 ? false : true
              if (!used) {
                zipCodes.push(region.properties.zipCode)
              }
              return region.geometry.coordinates.map((coord, i) => {
                let coordArr = []
                if (coord.length === 1) coord[0].map(coodinate => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
                else coord.map(coodinate => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
                polygons.push({
                  key: region.properties.zipCode + '_' + i,
                  coords: coordArr,
                  used: used,
                })
                return coordArr
              })
            })
            let errorZipCodes = zipCodes.length === 0 ? true : false
            this.setState({ zipCodes: zipCodes, errorZipCodes: errorZipCodes })
            this.setState({ polygons: polygons })
          } else {
            this.setState({ loadingSpin: false })
          }
        })
        .catch(err => {
          this.setState({ loadingSpin: false })
          console.error(err)
        })
    }
  }
  removeZipCode(id, zip) {
    const context = this
    const chefId = this.state.chefId
    let filteredArray = this.state.zips.filter(item => item !== zip)
    this.setState({
      zips: filteredArray,
    })
    chefClient
      .removeZip(id, zip)
      .then(response => {
        if (response.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Successfully deleted the zip!',
          })
          setTimeout(function() {
            context.fetchChefDetails(chefId)
          }, 500)
        } else {
          this.setState({ loadingSpin: false })
        }
      })
      .catch(error => {
        this.setState({
          loadingSpin: false,
        })
        this.setState({ loadingSpin: false })
      })
  }

  async removeAllZipCode(id) {
    const context = this
    await chefClient
      .removeAllZip(id)
      .then(response => {
        if (response.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Successfully deleted the all zip!',
          })
          context.fetchChefDetails(id)
        } else {
          this.setState({ loadingSpin: false })
        }
      })
      .catch(error => {
        this.setState({
          loadingSpin: false,
        })
        this.setState({ loadingSpin: false })
      })
  }

  async handleRadius(radius) {
    if (this.state.latitude && this.state.longitude) await this.searchZipCode(radius, Number(this.state.latitude), Number(this.state.longitude))
    const context = this
    context.setState({ radius: radius })
    var c = this.state.zips.concat(this.state.zipCodes)
    var d = c.filter((item, pos) => c.indexOf(item) === pos)
    this.setState({
      availableZips: d,
    })
  }
  checkValidation() {
    const checkZip = this.state.zip ? true : false
    return [checkZip]
  }

  handleOkModalAddZip() {
    const context = this
    const chefId = this.state.chefId
    const zip = this.state.zip
    const validation = this.checkValidation()
    if (Object.values(validation).includes(false)) {
      this.setState({
        validation: validation,
      })
      notification.error({
        message: 'Please Enter Zip!',
      })
    } else {
      chefClient
        .addZip(chefId, zip)
        .then(res => {
          if (res.status === 200) {
            if (res.status === 200) {
              notification.success({
                message: 'Successfully added the zip!',
              })
              setTimeout(function() {
                context.fetchChefDetails(chefId)
              }, 500)
              this.setState({ visible: false, zip: '' })
            } else {
              this.setState({ loading: false })
            }
          }
        })
        .catch(error => {
          this.setState({
            loadingSpin: false,
          })
          this.setState({ loading: false })
        })
    }
  }
  handleValidation() {
    var resultArray = []
    const { address } = this.state
    if (!address) {
      document.getElementById('address-input').style.border = '1px solid red'
      document.getElementById('address-input').style.borderRadius = '5px'
      document.getElementById('address-input-val').style.display = 'inline-block'
      resultArray.push(false)
    }
    return resultArray.length === 0
  }
  submitChefChanges() {
    const result = this.handleValidation()
    if (result) {
      this.setState({
        loadingSpin: true,
      })
      var chefInfo = {
        id: this.state.chefId,
        image: this.state.image,
        biography: this.state.biography,
        address: this.state.address,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        radius: this.state.radius,
        rate: this.state.rate,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.contactEmail,
        phone: this.state.phone,
        isAddressChanged: false,
      }
      const context = this
      const chefId = this.state.chefId
      chefClient
        .update(chefInfo)
        .then(response => {
          if (response.status === 200) {
            notification.success({
              message: 'Chef Saved',
              description: 'Chef has been successfully updated!',
            })
            chefClient
              .addZipList(chefId, { zips: this.state.availableZips })
              .then(res => {
                if (res.status === 200) {
                }
              })
              .catch(err => {
                console.error(err)
              })
            this.setState({
              loadingSpin: false,
            })
            setTimeout(function() {
              context.fetchChefDetails(chefId)
            }, 3000)
          } else {
            notification.error({
              message: 'Error!',
              description: 'Error occured on menu update!',
            })
            this.setState({
              loadingSpin: false,
            })
            console.error(response)
          }
        })
        .catch(error => {
          this.setState({
            loadingSpin: false,
          })
          console.error(error)
        })
    }
  }

  handleOnChangeFile(e) {
    this.setState({
      zipFile: e.target.files[0],
    })
  }
  handleOnSubmitFile(e) {
    e.preventDefault()
    const zipFile = this.state.zipFile
    if (zipFile) {
      let fileReader = new FileReader()
      fileReader.onload = async event => {
        var self = this
        const text = event.target.result
        await self.csvFileToArray(text)
      }

      fileReader.readAsText(zipFile)
    }
  }
  csvFileToArray(string) {
    const context = this
    const chefId = this.state.chefId
    const zipFile = this.state.zipFile

    const csvRows = string.slice(string.indexOf('\n') + 1).split('\r\n')

    this.setState({
      zipArray: csvRows,
    })
    if (this.state.zipArray) {
      this.setState({
        loadingSpin: true,
      })
      let newArr = this.state.zipArray.map((item, index, arr) => {
        return Number(item)
      })
      if (newArr.some(isNaN)) {
        this.setState({
          loadingSpin: false,
        })
        notification.error({
          message: 'Something went wrong, please try again.',
          description: `We couldn't upload ${zipFile.name}`,
        })
        this.myRef.current.value = ''
      } else {
        this.setState({
          loadingSpin: true,
        })
        chefClient
          .addZipList(chefId, { zips: newArr })
          .then(res => {
            if (res.status === 200) {
              this.myRef.current.value = ''
              notification.success({
                message: 'Chef Saved',
                description: 'Chef has been successfully updated!',
              })
              this.setState({
                loadingSpin: false,
              })
              setTimeout(function() {
                context.fetchChefDetails(chefId)
              }, 3000)
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }
  render() {
    const context = this
    const {
      menuLoad,
      chefId,
      firstName,
      lastName,
      biography,
      email,
      contactEmail,
      phone,
      address,
      radius,
      rate,
      image,
      zip,
      validation,
      isAddressChanged,
    } = this.state
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusCircleOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    )
    const rateFloat = Number.isInteger(rate) ? rate : Math.floor(rate) + 0.5
    if (chefId > 0 && menuLoad) {
      return (
        <Spin spinning={this.state.loadingSpin} tip="Loading">
          {isAddressChanged && <Alert message="Address changed!. Please update the chef zipcodes." type="warning" className="mb-2" showIcon closable />}
          <Portlet fluidHeight={true}>
            <PortletHeader title="Chef Details" />
            <PortletBody>
              <div style={{ marginBottom: '20px' }}>
                <Button
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/chef',
                      state: {
                        prevChefListPagination: context.props.history.location.state.prevChefListPagination
                          ? context.props.history.location.state.prevChefListPagination
                          : null,
                        prevCityId:
                          context.props.history.location.state && context.props.history.location.state.prevCityId
                            ? context.props.history.location.state.prevCityId
                            : null,
                      },
                    })
                  }
                >
                  <i className="kt-menu__ver-arrow la la-angle-left" />
                  Back to Chef List
                </Button>
              </div>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Row>
                    <Col>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader chef-image"
                        showUploadList={false}
                        customRequest={({ file, onSuccess, onError }) => {
                          setTimeout(() => {
                            onSuccess('ok', file)
                          }, 100)
                        }}
                        beforeUpload={this.beforeImageUpload}
                        onChange={this.handleImageChange}
                      >
                        {image.url ? (
                          <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                        ) : image.file.content !== '' ? (
                          <img src={'data:' + image.file.contentType + ';base64,' + image.file.content} alt="avatar" style={{ width: '100%' }} />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </Col>
                    <Col span={12}>
                      <Text strong={true}>Rate</Text>
                      <br />
                      <InputNumber placeholder="Rate" defaultValue={rate} min={0} max={5} step={0.1} onChange={this.handleRateChange} /> &nbsp;
                      <Rate allowHalf disabled defaultValue={rateFloat} value={rateFloat} />
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col>
                      <Text strong={true}>Chef Name</Text>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Input
                            placeholder="First name"
                            defaultValue={firstName}
                            onChange={e => {
                              context.setState({ firstName: e.target.value })
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            placeholder="Last name"
                            defaultValue={lastName}
                            onChange={e => {
                              context.setState({ lastName: e.target.value })
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={24}>
                      <Text strong={true}>Biography</Text>
                      <TextArea
                        placeholder="Please enter bio"
                        defaultValue={biography}
                        autoSize
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        onChange={e => {
                          context.setState({ biography: e.target.value })
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col>
                      <Text strong={true}>Email</Text>
                      <Input
                        placeholder="Please enter email address"
                        defaultValue={email}
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={24} md={12}>
                      <Text strong={true}>Contact Email</Text>
                      <Input
                        placeholder="Please enter contact email address"
                        defaultValue={contactEmail}
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        onChange={e => {
                          context.setState({ contactEmail: e.target.value })
                        }}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <Text strong={true}>Phone</Text>
                      <br />
                      <div className="phoneInput-wrapper" style={{ position: 'relative' }}>
                        <PhoneOutlined className="phoneInput-icon" style={{ color: 'rgba(0,0,0,.25)', fontSize: '14px' }} />
                        <InputMask
                          className="phoneInput-mask"
                          mask="(999) 999-9999"
                          placeholder="Please enter phone number"
                          value={phone}
                          onChange={e => {
                            context.setState({ phone: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col span={24}>
                      <Text strong={true}>
                        Address<span style={{ color: 'red' }}> *</span>
                      </Text>
                    </Col>
                    <Col className="location-search-container" span={24}>
                      <div id="address-input">
                        <span className="location-search-icon">
                          <PushpinOutlined className="phoneInput-icon" style={{ color: 'rgba(0,0,0,.25)', fontSize: '14px' }} />
                        </span>
                        <LocationSearchInput required handleAddress={this.handleAddress} name="address" address={address} />
                      </div>
                    </Col>
                    <Col xs={24}>
                      <span id="address-input-val" style={{ color: 'red', display: 'none' }}>
                        This field is required!
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col span={12}>
                      <Text strong={true}>Radius</Text>
                      <br />
                      <InputNumber placeholder="Radius" defaultValue={radius} onChange={e => this.handleRadius(e)} />
                      <span style={{ marginLeft: '8px' }}>mile(s)</span>
                    </Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: '1.5rem' }}>
                    <Col flex={'auto'} style={{ alignSelf: 'center' }}>
                      <Text strong={true}>Zipcodes</Text>
                    </Col>
                    <Col flex={'220px'}>
                      <Popconfirm
                        placement="top"
                        title="Are you sure to remove all zipcode ?"
                        onConfirm={() => this.removeAllZipCode(chefId)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <span style={{ textDecoration: 'underline', paddingRight: 20, cursor: 'pointer' }}> Remove All Zip</span>
                      </Popconfirm>
                      <Button onClick={() => context.setState({ visible: true })}>
                        <i className="kt-menu__ver-arrow la la-plus" />
                        Add Zip
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={0} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={20}>
                      <input
                        accept=".csv"
                        id="csvFileInput"
                        multiple
                        type="file"
                        className="form-control"
                        onChange={e => this.handleOnChangeFile(e)}
                        ref={this.myRef}
                        style={{ height: 'auto' }}
                      />
                    </Col>
                    <Col xs={4} className="w-100">
                      <Button
                        variant="contained"
                        component="span"
                        className="w-100"
                        style={{ height: '100%' }}
                        onClick={e => {
                          this.handleOnSubmitFile(e)
                        }}
                      >
                        Upload
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
                    <Col xs={24} style={{ maxHeight: 150, overflow: 'hidden', overflowY: 'auto' }}>
                      {this.state.availableZips.length > 0 ? (
                        this.state.availableZips.map((zip, idx) => (
                          <Popconfirm
                            placement="top"
                            title="Are you sure to remove this zipcode ?"
                            onConfirm={() => this.removeZipCode(chefId, zip)}
                            okText="Yes"
                            key={idx}
                            cancelText="No"
                          >
                            <button type="button" className="btn btn-secondary btn-pill btn-zipcode" key={zip}>
                              {zip}
                            </button>
                          </Popconfirm>
                        ))
                      ) : (
                        <div className="text-center text-gray2 py-2">You have no available zip list</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Button type="primary" htmlType="submit" onClick={this.submitChefChanges}>
                        Update Chef
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <MapComponent
                    address={this.state.address}
                    radius={this.state.radius}
                    center={{ lat: this.state.latitude, lng: this.state.longitude }}
                    polygons={this.state.polygons}
                    handleZipCode={this.handleZipCode}
                  />
                </Col>
              </Row>
              <Modal
                title="Add Zip"
                centered
                onOk={this.handleOkModalAddZip}
                visible={this.state.visible}
                okText="Submit"
                onCancel={() => context.setState({ visible: false, zip: '' })}
                destroyOnClose={true}
              >
                <div className="mb-3">
                  <Text strong={true}>Zip code</Text>
                  <Input
                    style={validation[0] ? {} : { border: '1px solid red' }}
                    placeholder="Zip Code"
                    value={zip}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={e => {
                      const newItems = [...validation]
                      newItems[0] = true
                      this.setState({
                        zip: e.target.value.toUpperCase(),
                        validation: newItems,
                      })
                    }}
                  />
                </div>
              </Modal>
            </PortletBody>
          </Portlet>
        </Spin>
      )
    } else {
      return (
        <Portlet fluidHeight={true}>
          <PortletHeader title="Chef Details" />
          <PortletBody />
        </Portlet>
      )
    }
  }
}

export default ChefDetails
