import React from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

class Message extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { message, date, sender, type } = this.props
    const formattedDate = moment(date).format('MM/DD/YYYY')
    const messageBubbleClass = type === 'Chef' ? 'message-bubble sender' : 'message-bubble'
    return (
      <Row className="mb-3">
        <Col span={24}>
          <div className={messageBubbleClass}>
            <div className="text-nowrap mb-3 mt-2" style={{ fontSize: 12, fontWeight: 500 }}>
              <span className="mr-5">
                {sender} - {type}
              </span>
              <span className="float-right" style={{ opacity: 0.6 }}>
                {formattedDate}
              </span>
            </div>
            <div>{message}</div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Message
