import ApiClient from './client'
const client = new ApiClient()

export default {
  list(paginationInfo) {
    return client.post('/api/Common/ListHomeMenuCategories', paginationInfo, {
      'Content-Type': 'application/json',
    })
  },

  create(name, cityId) {
    return client.post(
      '/api/Common/AddHomeMenuCategory',
      {
        name: name,
        cityId: cityId,
      },
      {
        'Content-Type': 'application/json',
      },
    )
  },
  edit(id, data) {
    return client.put(`/api/Common/HomeMenuCategory/${id}`, data, {
      'Content-Type': 'application/json',
    })
  },
  delete(id) {
    return client.delete('/api/Common/DeleteHomeMenuCategory/' + id, {
      'Content-Type': 'application/json',
    })
  },

  cityList() {
    return client.get('/api/Booking/ListCities', {
      'Content-Type': 'application/json',
    })
  },
}
