import ApiClient from './client';
const client = new ApiClient();

export default {
    list(paginationInfo) {
        return client.post('api/menu/TimePeriodsListPaginated', paginationInfo, {
            'Content-Type': 'application/json'
        });
    },

    create(name) {
        return client.post('api/menu/TimePeriodAdd', {
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    update(id, name) {
        return client.post('api/menu/TimePeriodUpdate', {
            'id': id,
            'name': name
        }, {
            'Content-Type': 'application/json'
        });
    },

    delete(id) {
        return client.delete('api/menu/TimePeriodDelete/' + id, {
            'Content-Type': 'application/json'
        });
    }
}   