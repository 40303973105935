import React, { useState, useEffect } from 'react'
import { Button, Table, Tag, Select, DatePicker, Input, InputNumber, Pagination, Modal, Row, Col, notification, Image, Spin, Carousel } from 'antd'
import { CSVLink } from 'react-csv'
import GoogleMapReact from 'google-map-react'
import httpStatus from 'http-status-codes'
import { BOOKING_STATUS } from '../components/enum/enum'
import bookingClient from '../pages/api/booking'
import moment from 'moment'
import LocationSearchInput from '../components/LocationSearchInput'
import 'antd/dist/antd.css'
import 'react-block-ui/style.css'

const { Option } = Select
const { RangePicker } = DatePicker
const googleApiKey = 'AIzaSyDh90ST6pPMBJTm2SwEnUB6j7ljOx7J8cU'
const AnyReactComponent = ({ text, className }) => <div className={'mapMarker ' + className}>{text}</div>
function currencyFormat(num) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num)
}
var chefLocationMarkers = []
function BookingTable(props) {
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [bookingData, setBookingData] = useState([])
  const [statusList, setStatusList] = useState([])
  const [total, setTotal] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(-1)
  const [searchValue, setSearchValue] = useState('')
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'))
  const [endDate, setEndDate] = useState(moment())
  const [mapVisible, setMapVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [bookingDetail, setBookingDetail] = useState({})
  const [bookingReceipts, setBookingReceipts] = useState({})
  const [bookingId, setBookingId] = useState(0)
  const [editBookingId, setEditBookingId] = useState(0)
  const [editBookingDetails, setEditBookingDetails] = useState(null)
  const [editBookingDateTime, setEditBookingDateTime] = useState(moment())
  const [editBookingAddress, setEditBookingAddress] = useState('')
  const [editBookingLat, setEditBookingLat] = useState(0)
  const [editBookingLong, setEditBookingLong] = useState(0)
  const [editBookingServings, setEditBookingServings] = useState(0)
  const [csvExportData, setCsvExportData] = useState([])
  const [moreIngredientModal, setMoreIngredientModal] = useState(false)
  const [moreIngredientData, setMoreIngredientData] = useState([])
  const [moreIngredientTitle, setMoreIngredientTitle] = useState('')
  const [imageVisible, setImageVisible] = useState(false)
  const [defaultLocations, setDefaultLocations] = useState({
    latitude: 0,
    longitude: 0,
  })
  const csvExportHeader = [
    { label: 'Booking ID', key: 'bookingId' },
    { label: 'Menu ID', key: 'menuId' },
    { label: 'Menu', key: 'menu' },
    { label: 'Category', key: 'category' },
    { label: 'Date', key: 'date' },
    { label: 'Time', key: 'time' },
    { label: 'Payment Date', key: 'paidDate' },
    { label: 'Experience Location', key: 'location' },
    { label: 'Chef Name', key: 'chefName' },
    { label: 'Chef Phone Number', key: 'chefPhone' },
    { label: 'Chef Email', key: 'chefEmail' },
    { label: 'Chef Address', key: 'chefAddress' },
    { label: 'Customer Name', key: 'customerName' },
    { label: 'Customer Phone Number', key: 'customerPhone' },
    { label: 'Customer Email', key: 'customerEmail' },
    { label: 'Booking Status', key: 'status' },
    { label: '# of Guest', key: 'servings' },
    { label: 'Subtotal', key: 'subtotal' },
    { label: 'Service Fee', key: 'serviceFee' },
    { label: 'Small Group Fee', key: 'smallGroupFee' },
    { label: 'Estimated Tax', key: 'estimatedTax' },
    { label: 'Gift Card', key: 'giftCard' },
    { label: 'Coupon', key: 'coupon' },
    { label: 'Tip', key: 'tip' },
    { label: 'Total', key: 'total' },
  ]
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      render: function(record, row) {
        return (
          <>
            <div className="text-center">
              <div className="font-weight-bold">{record}</div>
              {props.type === 'Upcoming' && (
                <div>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                      setEditVisible(true)
                      setEditBookingId(record)
                      setEditBookingServings(row.servings)
                      setEditBookingDateTime(moment(row.date))
                      setEditBookingAddress(row.address)
                      setEditBookingDetails({
                        chef: row.chefName,
                        menu: row.menuName,
                        customer: row.customerName,
                      })
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
          </>
        )
      },
    },
    {
      title: 'Menu',
      render: function(record, row) {
        return (
          <>
            <div>
              <b>{record.menuName}</b>
            </div>
            <div className="text-nowrap">Category: {row.menuCategory}</div>
            {record.bookingReceipts.length > 0 && (
              <>
                <Button
                  type="primary"
                  onClick={async () => {
                    setTimeout(() => {
                      setLoading(true)
                      setImageVisible(true)
                      if (record.bookingReceipts.length > 0) {
                        setBookingReceipts(record.bookingReceipts)
                      }
                    }, 1000)
                  }}
                  style={{ fontSize: 10, padding: '4px 8px' }}
                >
                  Show Receipts
                </Button>
              </>
            )}
          </>
        )
      },
    },
    {
      title: 'Experience Date/Time',
      dataIndex: 'date',
      className: 'text-center',
      render: function(record) {
        return (
          <>
            <div>{moment(record).format('MM/DD/YYYY')}</div>
            <div className="text-nowrap">{moment(record).format('HH:mm A')}</div>
          </>
        )
      },
    },
    {
      title: 'Payment Date',
      dataIndex: 'paidDate',
      className: 'text-center',
      render: function(record) {
        return (
          <>
            <div>{moment(record).format('MM/DD/YYYY')}</div>
            {/* <div className="text-nowrap">{moment(record).format('HH:mm A')}</div> */}
          </>
        )
      },
    },
    {
      title: 'Chef',
      dataIndex: 'chefName',
      render: function(record, row) {
        const emailLink = 'mailto:' + row.chefEmail
        const phoneLink = 'tel:' + row.chefPhone
        return (
          <>
            <div>
              <b>{row.chefName}</b>
            </div>
            <div className="text-nowrap">
              <i className="fa fa-phone mr-2"></i>
              <a href={phoneLink}>{row.chefPhone}</a>
            </div>
            <div className="text-nowrap">
              <i className="fa fa-envelope mr-2"></i>
              <a href={emailLink}>{row.chefEmail}</a>
            </div>
            <div className="text-nowrap">
              <i className="fa fa-map mr-2"></i>
              {row.chefAddress}
            </div>
          </>
        )
      },
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      render: function(record, row) {
        const emailLink = 'mailto:' + row.customerEmail
        const phoneLink = 'tel:' + row.customerPhone
        return (
          <>
            <div>
              <b>{row.customerName}</b>
            </div>
            <div className="text-nowrap">
              <i className="fa fa-phone mr-2"></i>
              <a href={phoneLink}>{row.customerPhone}</a>
            </div>
            <div className="text-nowrap">
              <i className="fa fa-envelope mr-2"></i>
              <a href={emailLink}>{row.customerEmail}</a>
            </div>
          </>
        )
      },
    },
    {
      title: 'Status',
      className: 'text-center',
      dataIndex: 'status',
      render: function(record, row) {
        return <div>{getStatus(record, row.statusDate)}</div>
      },
    },
    {
      title: 'Ingredients',
      className: 'text-left',
      render: function(record, row) {
        return (
          <>
            {record.ingredients.length > 3 ? (
              <span>
                <dl>
                  {record.ingredients.map((ingredient, idx) => {
                    if (idx < 2)
                      return (
                        <span key={idx}>
                          <dt>{ingredient.name}</dt>
                          <dd className="ml-3">
                            {ingredient.size} {ingredient.unitName}
                          </dd>
                        </span>
                      )
                    else return ''
                  })}
                </dl>
                <Button
                  onClick={() => {
                    setMoreIngredientModal(true)
                    setMoreIngredientData(record.ingredients)
                    setMoreIngredientTitle(row.menuName)
                  }}
                >
                  More
                </Button>
              </span>
            ) : record.ingredients.length > 0 ? (
              <>
                <dl>
                  {record.ingredients.map((ingredient, idx) => {
                    return (
                      <span key={idx}>
                        <dt>{ingredient.name}</dt>
                        <dd className="ml-3">
                          {ingredient.size} {ingredient.unitName}
                        </dd>
                      </span>
                    )
                  })}
                </dl>
              </>
            ) : (
              ''
            )}
          </>
        )
      },
    },
    {
      title: 'Location',
      render: record => {
        return (
          <>
            <div>{record.address}</div>
            <div>
              <Button
                className={`booking-view`}
                onClick={() => {
                  setMapVisible(true)
                  setBookingId(record.id)
                  setBookingDetail({
                    address: record.address,
                    status: record.status,
                    statusDate: record.statusDate,
                  })
                }}
              >
                <i className="fa fa-map-pin mr-2"></i>
                Map
              </Button>
            </div>
          </>
        )
      },
    },
    {
      title: '# of Guests',
      dataIndex: 'servings',
    },
    {
      title: 'Amount',
      render: (_, record) => {
        const extraFees = record.bookingFees.filter(element => {
          return element.type === 3 || element.type === 4
        })
        const fees = record.bookingFees.filter(element => {
          return element.type === 0 || element.type === 1 || element.type === 2
        })
        return (
          <div>
            <table>
              <tbody>
                {record.bookingFees ? (
                  <>
                    <div style={{ display: 'contents' }}>
                      {extraFees.map(function(el) {
                        return (
                          <tr key={el.name} className="text-nowrap">
                            {el.type === 4 && (
                              <>
                                <td style={{ color: '#d85845' }}>{el.name}</td>
                                <td className="text-right" style={{ color: '#d85845' }}>
                                  {el.amount > 0 ? '$' + el.amount : '-$' + el.amount * -1}
                                </td>
                              </>
                            )}
                            {el.type === 3 && (
                              <>
                                <td style={{ color: '#4e9a06bf' }}>{el.name}</td>
                                <td className="text-right" style={{ color: '#4e9a06bf' }}>
                                  {el.amount > 0 ? '$' + el.amount : '-$' + el.amount * -1}
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      })}
                      {extraFees?.length > 0 && <tr style={{ paddingBottom: 10, display: 'block' }}> </tr>}
                    </div>
                    {fees.map(function(el) {
                      return (
                        <tr key={el.name} className="text-nowrap">
                          {el.amount > 0 ? (
                            <>
                              <td>{el.name}</td>
                              <td className="text-right">{el.amount > 0 ? '$' + el.amount : '-$' + el.amount * -1}</td>
                            </>
                          ) : (
                            <>
                              <td style={{ color: 'red' }}>{el.name}</td>
                              <td className="text-right" style={{ color: 'red' }}>
                                {el.amount > 0 ? '$' + el.amount : '-$' + el.amount * -1}
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  </>
                ) : (
                  ''
                )}
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-nowrap">
                  <td>
                    <b>Total</b>
                  </td>
                  <td className="text-right">{currencyFormat(record.amount)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      },
    },
    {
      title: 'Tip',
      dataIndex: 'tip',
      className: 'text-center',
      render: tip => (tip ? currencyFormat(tip) : '-'),
    },
  ]
  useEffect(() => {
    if (props.type === 'Upcoming') {
      setStatusList([
        {
          id: 0,
          name: 'Pending',
        },
        {
          id: 1,
          name: 'Booked',
        },
        {
          id: 3,
          name: 'On Way',
        },
        {
          id: 4,
          name: 'Arrived',
        },
      ])
      setStartDate(moment())
      setEndDate(moment().add(1, 'months'))
    } else if (props.type === 'Past') {
      setStatusList([{ id: 5, name: 'Finished' }])
    } else if (props.type === 'Cancelled') {
      setStatusList([
        {
          id: 2,
          name: 'Declined',
        },
        {
          id: 6,
          name: 'Cancelled By Chef',
        },
        {
          id: 7,
          name: 'Cancelled By Customer',
        },
      ])
    }
    fetchBookingList(props.type === 'Upcoming')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (loading) {
      fetchBookingList()
    }
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (bookingId) {
      bookingClient.advancedDetails(bookingId).then(response => {
        chefLocationMarkers = response.data.chefLocations
        setDefaultLocations(response.data.location)
      })
    }
  }, [bookingId]) // eslint-disable-line react-hooks/exhaustive-deps
  const getStatus = (statusCode, statusDate, forExportData) => {
    let statusColor = ''
    let statusText = ''
    switch (statusCode) {
      case BOOKING_STATUS.Pending: {
        statusColor = '#d6ca20'
        statusText = 'Pending'
        break
      }
      case BOOKING_STATUS.Booked: {
        statusColor = '#2db7f5'
        statusText = 'Booked'
        break
      }
      case BOOKING_STATUS.Declined: {
        statusColor = '#f50'
        statusText = 'Declined'
        break
      }
      case BOOKING_STATUS.OnWay: {
        statusColor = '#d6ca20'
        statusText = 'On Way'
        break
      }
      case BOOKING_STATUS.Arrived: {
        statusColor = '#87d068'
        statusText = 'Arrived at ' + moment(statusDate).format('MM/DD/YYYY')
        break
      }
      case BOOKING_STATUS.Finished: {
        statusColor = '#87d068'
        statusText = 'Finished'
        break
      }
      case BOOKING_STATUS.CancelledByChef: {
        statusColor = '#f50'
        statusText = 'Cancelled By Chef'
        break
      }
      case BOOKING_STATUS.CancelledByCustomer: {
        statusColor = '#f50'
        statusText = 'Cancelled By Customer'
        break
      }
      default:
        break
    }
    if (forExportData) return statusText
    else return <Tag color={statusColor}>{statusText}</Tag>
  }
  const fetchBookingList = async isFirstTime => {
    const data = {
      search: searchValue,
      pagination: pagination,
    }
    if (isFirstTime) {
      data.startDate = moment()
      data.endDate = moment().add(1, 'months')
    } else {
      data.startDate = startDate
      data.endDate = endDate
    }
    if (selectedStatus >= 0) {
      data.status = selectedStatus
    }
    bookingClient
      .advancedList(`/api/Booking/${props.type}Bookings`, data)
      .then(response => {
        if (response.status === httpStatus.OK) {
          const bookingData = response.data.data.map(item => {
            return {
              id: item.id,

              chefId: item.chef.id,
              chefName: item.chef.name,
              chefAddress: item.chef.address,
              chefEmail: item.chef.email,
              chefPhone: item.chef.phone,

              customerId: item.customer.id,
              customerName: item.customer.name,
              customerEmail: item.customer.email,
              customerPhone: item.customer.phone,

              menuId: item.menu.id,
              menuName: item.menu.name,
              menuCategory: item.menu.categoryName,
              amount: item.amount,
              bookingFees: item.bookingFees.sort((a, b) => (a.type > b.type ? 1 : -1)),
              bookingReceipts: item.bookingReceipts,

              date: item.date,
              paidDate: item.paidDate,
              address: item.address,
              status: item.status,
              statusDate: item.statusDate,
              ingredients: item.ingredients,
              servings: item.servings,
              tip: item.tip,
            }
          })
          setBookingData(bookingData)
          const exportData = response.data.data.map(item => {
            const subtotal = item.bookingFees.find(x => x.name === 'Subtotal')
            const serviceFee = item.bookingFees.find(x => x.name === 'Service Fee')
            const smallGroupFee = item.bookingFees.find(x => x.name.startsWith('Small Group'))
            const estimatedTax = item.bookingFees.find(x => x.name === 'Estimated Tax')
            const giftCard = item.bookingFees.find(x => x.name.startsWith('Gift Card'))
            const coupon = item.bookingFees.find(x => x.name.startsWith('Coupon'))
            const subtotalAmount = subtotal ? subtotal.amount : 0
            const serviceFeeAmount = serviceFee ? serviceFee.amount : 0
            const smallGroupFeeAmount = smallGroupFee ? smallGroupFee.amount : 0
            const estimatedTaxAmount = estimatedTax ? estimatedTax.amount : 0
            const giftCardAmount = giftCard ? giftCard.amount : 0
            const couponAmount = coupon ? coupon.amount : 0
            const total = subtotalAmount + serviceFeeAmount + smallGroupFeeAmount + estimatedTaxAmount + giftCardAmount + couponAmount
            return {
              bookingId: item.id,
              menuId: item.menu.id,
              menu: item.menu.name,
              category: item.menu.categoryName,
              date: moment(item.date).format('MM/DD/YYYY'),
              time: moment(item.date).format('hh:mm A'),
              paidDate: moment(item.paidDate).format('MM/DD/YYYY'),
              location: item.address,
              chefName: item.chef.name,
              chefAddress: item.chef.address,
              chefEmail: item.chef.email,
              chefPhone: item.chef.phone,
              customerName: item.customer.name,
              customerEmail: item.customer.email,
              customerPhone: item.customer.phone,
              status: getStatus(item.status, item.statusDate, true),
              servings: item.servings,
              subtotal: subtotalAmount,
              serviceFee: serviceFeeAmount,
              smallGroupFee: smallGroupFeeAmount,
              estimatedTax: estimatedTaxAmount,
              giftCard: giftCardAmount,
              coupon: couponAmount,
              tip: '$' + item.tip,
              total: total,
            }
          })
          setCsvExportData(exportData)
          setTotal(response.data.total)
          setLoading(false)
        } else {
          console.error(response)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const handlePaginationChange = async (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize,
    })
    setLoading(true)
  }
  return (
    <div>
      <div className="mb-4 mt-2">
        <span>
          <span className="mr-2">Status:</span>
          <Select
            showSearch
            allowClear={false}
            style={{ width: 240 }}
            onChange={async value => {
              setSelectedStatus(value)
              setLoading(true)
            }}
            value={selectedStatus}
          >
            <Option value={-1} key={-1}>
              All
            </Option>
            {statusList.map(function(el) {
              return (
                <Option value={el.id} key={el.id}>
                  {el.name}
                </Option>
              )
            })}
          </Select>
        </span>
        <span className="ml-4">
          <span className="mr-2">Date Range:</span>
          <RangePicker
            allowClear={false}
            format="MM/DD/YYYY"
            value={[moment(startDate), moment(endDate)]}
            style={{ width: '240px' }}
            onChange={value => {
              setStartDate(moment(value[0]))
              setEndDate(moment(value[1]))
              setLoading(true)
            }}
          />
        </span>
        <span className="ml-4">
          <CSVLink data={csvExportData} headers={csvExportHeader} filename={'booking-list.csv'} className="ant-btn">
            <i className="fa fa-download mr-2"></i>
            Export to .csv
          </CSVLink>
        </span>
        <span className="float-right">
          <Input
            placeholder="Search..."
            style={{ width: 200 }}
            onChange={e => {
              setSearchValue(e.target.value)
              setLoading(true)
            }}
          />
        </span>
      </div>
      <Table
        className="booking-list-table"
        dataSource={bookingData}
        bordered={true}
        columns={columns}
        rowKey={record => record.id}
        loading={loading}
        pagination={false}
        style={{ color: '#111!important' }}
        scroll={{ x: 400 }}
      />
      <Pagination
        className="p-3 float-right"
        showSizeChanger
        onShowSizeChange={handlePaginationChange}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={total}
        onChange={handlePaginationChange}
      />
      <Modal
        title={'Map of booking progress (' + chefLocationMarkers.length + ')'}
        centered
        visible={mapVisible}
        closeIcon={<i className="fa fa-times" />}
        destroyOnClose={true}
        width="80%"
        onCancel={() => {
          setMapVisible(false)
          setBookingId(0)
          setDefaultLocations({
            latitude: 0,
            longitude: 0,
          })
        }}
        footer={false}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Row gutter={24} style={{ marginBottom: '1.5rem' }}>
              <Col>
                <span>
                  <b>Status:</b>
                </span>
                <span>{getStatus(bookingDetail.status, bookingDetail.statusDate)}</span>
              </Col>
              <Col>
                <span className="mr-2">
                  <b>Address:</b>
                </span>
                <span>{bookingDetail.address}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: 'inline-block',
                width: '100%',
                height: '500px',
                paddingRight: '1rem',
              }}
            >
              {defaultLocations.latitude > 0 ? (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleApiKey }}
                  defaultZoom={11}
                  defaultCenter={{
                    lat: parseFloat(defaultLocations.latitude),
                    lng: parseFloat(defaultLocations.longitude),
                  }}
                >
                  <AnyReactComponent
                    lat={parseFloat(defaultLocations.latitude)}
                    lng={parseFloat(defaultLocations.longitude)}
                    text={''}
                    className="mainLocation"
                    key={-1}
                  />
                  {chefLocationMarkers.map((el, idx) => {
                    if (idx === chefLocationMarkers.length - 1) {
                      return <AnyReactComponent lat={parseFloat(el.latitude)} lng={parseFloat(el.longitude)} text={''} className="chefLastLocation" key={idx} />
                    } else
                      return (
                        <AnyReactComponent lat={parseFloat(el.latitude)} lng={parseFloat(el.longitude)} text={idx + 1} className="chefLocation" key={idx} />
                      )
                  })}
                </GoogleMapReact>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        title={'Ingredients'}
        centered
        visible={moreIngredientModal}
        closeIcon={<i className="fa fa-times" />}
        destroyOnClose={true}
        onCancel={() => {
          setMoreIngredientModal(false)
        }}
        footer={false}
      >
        <Row gutter={24}>
          <Col span={24} style={{ maxHeight: 400, overflowY: 'auto' }}>
            <div className="mb-3" style={{ color: '#1890ff', fontSize: '18px' }}>
              {moreIngredientTitle}
            </div>
            <dl>
              {moreIngredientData.map((ingredient, idx) => {
                return (
                  <span key={idx}>
                    <dt>{ingredient.name}</dt>
                    <dd className="ml-3">
                      {ingredient.size} {ingredient.unitName}
                    </dd>
                  </span>
                )
              })}
            </dl>
          </Col>
        </Row>
      </Modal>
      <Modal visible={imageVisible} title="Receipts" centered onOk={() => setImageVisible(false)} onCancel={() => setImageVisible(false)}>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            {bookingReceipts.length > 0 ? (
              <Spin spinning={loading} tip="Loading ...">
                <Carousel arrows className="booking-carousel" dots={false}>
                  {bookingReceipts.map((receipt, i) => {
                    return (
                      <div className="d-block" style={{ width: 400 }}>
                        <div
                          style={{
                            position: 'absolute',
                            background: '#d85845bf',
                            borderRadius: '50%',
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '30px',
                            height: '30px',
                          }}
                        >
                          {i + 1}
                        </div>
                        <div className="text-center mb-2">
                          <b>Category :</b> {receipt.receiptCategoryName}
                        </div>
                        <Image src={receipt.url} width={320} height={320} />
                        <div className="text-center mt-2">
                          <b>Amount :</b> ${receipt.amount}
                        </div>
                      </div>
                    )
                  })}
                </Carousel>
              </Spin>
            ) : (
              <div className="text-center py-2" style={{ color: '#999' }}>
                You have no receipt images
              </div>
            )}
          </Col>
        </Row>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
      </Modal>

      <Modal
        title={'Booking Details (#' + editBookingId + ')'}
        centered
        visible={editVisible}
        closeIcon={<i className="fa fa-times" />}
        destroyOnClose={true}
        width={600}
        okText="Update"
        onOk={() => {
          if (editBookingId > 0) {
            bookingClient
              .updateBooking(editBookingId, {
                servings: editBookingServings,
                date: moment(editBookingDateTime)
                  .utcOffset(0, true)
                  .format(),
                address: editBookingAddress,
                latitude: editBookingLat.toString(),
                longitude: editBookingLong.toString(),
              })
              .then(response => {
                setEditVisible(false)
                setEditBookingId(0)
                setEditBookingServings(0)
                setEditBookingDateTime(moment())
                setEditBookingAddress('')
                setEditBookingLat(0)
                setEditBookingLong(0)
                setEditBookingDetails(null)
                setLoading(true)
                notification.success({
                  message: 'Booking is successfully updated!',
                })
              })
          }
        }}
        onCancel={() => {
          setEditVisible(false)
          setEditBookingId(0)
          setEditBookingServings(0)
          setEditBookingDateTime(moment())
          setEditBookingAddress('')
          setEditBookingLat(0)
          setEditBookingLong(0)
          setEditBookingDetails(null)
        }}
      >
        <div>
          <Row className="mb-3">
            <Col span={10}>
              <div>Menu</div>
              <div>
                <b>{editBookingDetails ? editBookingDetails.menu : ''}</b>
              </div>
            </Col>
            <Col span={14}>
              <div>Servings</div>
              <div>
                <InputNumber
                  min={1}
                  value={editBookingServings}
                  onChange={e => {
                    setEditBookingServings(e)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={10}>
              <div>Chef</div>
              <div>
                <b>{editBookingDetails ? editBookingDetails.chef : ''}</b>
              </div>
            </Col>
            <Col span={14}>
              <div>Booking Date</div>
              <div>
                <DatePicker
                  format="MM/DD/YYYY HH:mm"
                  allowClear={false}
                  showTime
                  value={editBookingDateTime}
                  onOk={date => {
                    setEditBookingDateTime(date)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <div>Customer</div>
              <div>
                <b>{editBookingDetails ? editBookingDetails.customer : ''}</b>
              </div>
            </Col>
            <Col span={14}>
              <div>Booking Location</div>
              <div className="bookingEditLocationInput">
                <LocationSearchInput
                  required
                  handleAddress={(address, lat, long) => {
                    setEditBookingAddress(address)
                    setEditBookingLat(lat)
                    setEditBookingLong(long)
                  }}
                  name="address"
                  address={editBookingAddress}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default BookingTable
