import ApiClient from './client'
const client = new ApiClient()

export default {
  list(paginationInfo) {
    return client.post('/api/Common/HolidayFeeListPaginated', paginationInfo, {
      'Content-Type': 'application/json',
    })
  },
  create(data) {
    return client.post('/api/Common/HolidayFee', data, {
      'Content-Type': 'application/json',
    })
  },
  delete(id) {
    return client.delete('/api/Common/HolidayFee/' + id, {
      'Content-Type': 'application/json',
    })
  },
  edit(id, data) {
    return client.put('/api/Common/HolidayFee/' + id, data, {
      'Content-Type': 'application/json',
    })
  },
}
