import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Tag, Table, Button, Pagination, Popconfirm, notification, Select } from 'antd'
import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import httpStatus from 'http-status-codes'
import chefClient from '../pages/api/chef'
import cityClient from '../pages/api/city'
import { LazyImage } from './LazyImage'
import { Tooltip } from '@material-ui/core'
const { Option } = Select

function ChefTable(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [searchCityId, setSearchCityId] = useState(undefined)
  const [chefListData, setChefListData] = useState([])
  const [total, setTotal] = useState(0)

  const [rows, setRows] = useState([])
  const fetchCityList = useCallback(() => {
    setLoading(true)
    cityClient
      .list()
      .then(response => {
        if (response.status === 200) {
          const rows = response.data.map(item => {
            return {
              id: item.id,
              name: item.name,
            }
          })
          setRows(rows)
          setLoading(false)
        }
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])
  useEffect(() => {
    fetchCityList()
  }, [fetchCityList])

  const columns = [
    {
      title: 'Picture',
      width: '130px',
      dataIndex: 'imageUrl',
      render: function(imageUrl) {
        return (
          <>
            <div style={{ width: '100%', maxWidth: '160px', height: '120px', maxHeight: '200px', overflow: 'hidden' }}>
              <LazyImage src={imageUrl} alt="placeHolder" />
            </div>
          </>
        )
      },
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: '140px',
      render: function(phone) {
        return <span className="text-nowrap">{phone}</span>
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: function(address) {
        return <span>{address}</span>
      },
    },
    {
      title: 'Radius',
      dataIndex: 'radius',
      render: function(radius) {
        return <span>{radius === 1 ? radius + ' mile' : radius + ' miles'}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: function(status) {
        return (
          <span>
            {status === 'Active' ? (
              <Tag color="success">{status}</Tag>
            ) : status === 'Pending' ? (
              <Tag color="warning">{status}</Tag>
            ) : status === 'Incomplete' ? (
              <Tag color="error">{status}</Tag>
            ) : status === 'Inactive' ? (
              <Tag color="default">{status}</Tag>
            ) : (
              <></>
            )}
          </span>
        )
      },
    },
    {
      title: 'Action',
      width: '130px',
      render: (text, record) => (
        <div className="text-nowrap">
          <Button className="menu-edit mr-2 color-white" onClick={() => handleEditChef(record)} type="primary">
            <i className="fa fa-edit mr-2" /> Edit
          </Button>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={e => {
              handleDeleteChef(record)
            }}
          >
            <Button className="courseType-delete mr-2" type="danger">
              <i className="fa fa-trash-alt" />
            </Button>
          </Popconfirm>
          {props.status === 'Active' && (
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={e => {
                handleDeactiveChef(record.id, false)
              }}
            >
              <Tooltip placement="top" title="Deactivate">
                <Button className="courseType-delete" type="secondary">
                  <i className="fa fa-ban" />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          {props.status === 'Inactive' && (
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={e => {
                handleDeactiveChef(record.id, true)
              }}
            >
              <Tooltip placement="top" title="Active">
                <Button className="courseType-delete" type="secondary" style={{ background: '#52c41a', color: '#FFF' }}>
                  <i className="fa fa-check" />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]
  useEffect(() => {
    setLoading(true)
    setPagination({ current: 1, pageSize: 10 })
    const historyState = history.location.state
    if (historyState && historyState.prevCityId) {
      setSearchCityId(historyState.prevCityId)
    }
    if (historyState && historyState.prevChefListPagination && props.status === 'Active') {
      setPagination(historyState.prevChefListPagination)
    } else {
      setPagination({ current: 1, pageSize: 10 })
    }
  }, [props.status]) // eslint-disable-line react-hooks/exhaustive-deps
  const fetchChefList = useCallback(() => {
    const data = {
      status: props.status.toLowerCase(),
      search: '',
      pagination: pagination,
      sorter: {
        field: '',
        order: '',
      },
    }
    if (searchCityId) {
      data.cityId = searchCityId
    }
    chefClient
      .list(data)
      .then(response => {
        if (response.status === httpStatus.OK) {
          const chefListData = response.data.data.map(item => {
            return {
              id: item.id,
              imageId: item.imageId,
              imageUrl: item.imageUrl,
              firstName: item.firstName,
              lastName: item.lastName,
              bio: item.biography,
              email: item.email,
              phone: item.phone,
              address: item.address,
              radius: item.radius,
              status: item.status,
              isAddressChanged: item.isAddressChanged,
            }
          })
          setChefListData(chefListData)
          setTotal(response.data.total)
          setLoading(false)
        }
      })
      .catch(error => {
        notification.error({
          message: 'Sorry...',
          description: `Request failed.`,
        })
        setLoading(false)
      })
  }, [pagination, searchCityId, props])
  useEffect(() => {
    if (loading) {
      fetchChefList()
    }
  }, [loading, fetchChefList])
  const handleEditChef = record => {
    history.push({
      pathname: '/chef-details',
      state: {
        chefId: record.id,
        prevChefListPagination: pagination,
        prevCityId: searchCityId,
      },
    })
  }
  const handleDeleteChef = record => {
    if (record !== null) {
      chefClient.delete(record.id).then(res => {
        if (res.status === httpStatus.OK) {
          setLoading(true)
          notification.success({
            message: 'Chef deleted',
            description: 'Chef has been successfully deleted!',
          })
        }
      })
    }
  }

  const handleDeactiveChef = (id, status) => {
    if (id !== null) {
      chefClient
        .deactive(id, status)
        .then(res => {
          if (res.status === httpStatus.OK) {
            notification.success({
              message: 'Chef deactivate',
              description: `Chef has been successfully ${status ? `activated` : 'deactivated'}!`,
            })
          }
        })
        .finally(() => {
          setLoading(true)
        })
        .catch(() => {
          setLoading(false)
          notification.warning({
            message: 'This chef has upcoming bookings. Please cancel the experience first.',
          })
        })
    }
  }

  const handlePaginationChange = async (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize,
    })
    setLoading(true)
  }

  return (
    <div>
      <span style={{ float: 'right', top: -55, position: 'relative' }}>
        <span>City name: </span>
        <Select
          showSearch
          allowClear={false}
          value={searchCityId}
          style={{ width: 320, marginBottom: '1rem' }}
          onChange={async e => {
            await setSearchCityId(e)
            setLoading(true)
            fetchChefList()
          }}
          optionFilterProp="children"
          defaultValue={0}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value={0} key={0}>
            All
          </Option>
          {rows &&
            rows.length > 0 &&
            rows.map(function(el) {
              return (
                <Option value={el.id} key={el.id}>
                  {el.name}
                </Option>
              )
            })}
        </Select>
      </span>
      <Table
        className="chef-list-table"
        dataSource={chefListData}
        bordered={true}
        columns={columns}
        rowKey={record => record.id}
        loading={loading}
        pagination={false}
        rowClassName={record => record.isAddressChanged === true && 'bg-address'}
        style={{ color: '#111!important' }}
      />
      <Pagination
        className="p-3 float-right"
        showSizeChanger
        onShowSizeChange={handlePaginationChange}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={total}
        onChange={handlePaginationChange}
      />
    </div>
  )
}

export default ChefTable
