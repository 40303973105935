import React from 'react'
import axios from 'axios'
import httpStatus from 'http-status-codes'
import { CSVLink } from 'react-csv'
import { Portlet, PortletBody, PortletHeader } from '../../partials/content/Portlet'
import {
  Select,
  Pagination,
  Tag,
  Button,
  Collapse,
  Modal,
  Input,
  InputNumber,
  Table,
  Carousel,
  Popconfirm,
  Switch,
  Typography,
  notification,
  Tooltip,
  Row,
  Col,
} from 'antd'
import { EditOutlined, DeleteOutlined, SaveOutlined, CopyOutlined } from '@ant-design/icons'
// import { SelectOutlined } from '@ant-design/icons'
import menuClient from '../api/menu'
import accountClient from '../api/account'
import cityClient from '../api/city'
import 'antd/dist/antd.css'
import 'react-block-ui/style.css'
import '../styles/table.scss'
import '../styles/menuList.scss'
import { LazyImage } from '../../components/LazyImage'
const { Option } = Select
const { Panel } = Collapse
const { TextArea } = Input
const { Text } = Typography

// DEFAULT HEADER - CSV FILE
let csvExportHeader = [
  { label: 'Menu_id', key: 'menuId' },
  { label: 'Menu_Name', key: 'menuName' },
  { label: 'Course1_Name', key: 'course1name' },
  { label: 'Course1_description', key: 'course1description' },
  { label: 'Course2_Name', key: 'course2name' },
  { label: 'Course2_description', key: 'course2description' },
  { label: 'Course3_Name', key: 'course3name' },
  { label: 'Course3_description', key: 'course3description' },
  { label: 'Course4_Name', key: 'course4name' },
  { label: 'Course4_description', key: 'course4description' },
  { label: 'Chef_id', key: 'chefId' },
  { label: 'Chef_name', key: 'chefName' },
]

class MenuList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuData: [],
      cityList: [],
      recommendedList: [],
      recommendVisible: false,
      loading: true,
      value: '',
      totalCount: 0,
      newEvent: null,
      chefList: [],
      csvExportData: [],
      menuId: 0,
      modalVisible: false,
      menuActionId: 0,
      menuActionType: 0,
      menuActionActualPrice: 0,
      menuActionReason: 0,
      modalTitle: '',
      searchZip: '',
      showArchivedMenu: false,
      showPendingMenu: false,
      showRecommendMenu: false,
      selectedChefId: undefined,
      selectedCityId: undefined,
      pagination: { current: 1, pageSize: 10 },
    }
    this.fetchMenuList = this.fetchMenuList.bind(this)
    this.fetchApprovedMenuList = this.fetchApprovedMenuList.bind(this)
    this.handleModalVisibility = this.handleModalVisibility.bind(this)
    this.handleInputPrice = this.handleInputPrice.bind(this)
    this.handleInputReason = this.handleInputReason.bind(this)
    this.handleCreateMenu = this.handleCreateMenu.bind(this)
    this.handleArchiveMenu = this.handleArchiveMenu.bind(this)
    this.handleDuplicateMenu = this.handleDuplicateMenu.bind(this)
    this.handleUnArchiveMenu = this.handleUnArchiveMenu.bind(this)
    this.handleArchiveSwitch = this.handleArchiveSwitch.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.handleDeleteMenu = this.handleDeleteMenu.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }
  async componentDidMount() {
    const prevState = this.props.history.location.state
    if (prevState) {
      if (prevState.prevMenuListPagination) {
        await this.setState({
          pagination: prevState.prevMenuListPagination,
        })
      }
      if (prevState.prevChefId) {
        await this.setState({
          selectedChefId: prevState.prevChefId,
        })
      }
      if (prevState.prevCityId) {
        await this.setState({
          selectedCityId: prevState.prevCityId,
        })
      }
    }
    await accountClient.listAllChef().then(async res => {
      await this.setState({
        chefList: res.data,
      })
    })
    await cityClient.list().then(async res => {
      if (res.status === 200) {
        const rows = res.data.map(item => {
          return {
            id: item.id,
            name: item.name,
          }
        })
        await this.setState({
          cityList: rows,
        })
      }
    })
    this.fetchMenuList()
    this.fetchApprovedMenuList()
  }
  handleModalVisibility = (modalVisible, type, id) => {
    this.setState({ modalVisible })
    if (modalVisible) {
      if (type === 'decline') {
        this.setState({
          menuActionType: 2,
          modalTitle: 'Decline Menu',
          menuActionId: id,
        })
      } else if (type === 'approve') {
        this.setState({
          menuActionType: 1,
          modalTitle: 'Approve Menu',
          menuActionId: id,
        })
      }
    }
  }

  handleInputPrice(value) {
    this.setState({ menuActionActualPrice: value })
  }
  fetchApprovedMenuList() {
    const { selectedChefId, searchZip, selectedCityId } = this.state
    const data = {
      chefId: selectedChefId > 0 ? selectedChefId : undefined,
      search: '',
      zip: searchZip ? searchZip : '',
      pagination: { current: 1, pageSize: 100000 },
      sorter: {
        field: 'Status',
        order: 'ascend',
      },
      status: 1,
    }
    if (selectedCityId) {
      data.cityId = selectedCityId
    }
    this.setState({
      loading: true,
    })
    menuClient.list(data).then(res => {
      if (res.status === httpStatus.OK) {
        const rows = res.data.data.map(item => {
          return {
            id: item.id,
            name: item.name,
            categoryName: item.categoryName,
            chefId: item.chefId,
            chefName: item.chefFirstName + ' ' + item.chefLastName,
            description: item.description,
            imageUrls: item.imageUrls,
            price: item.price,
            tags: item.tags,
            courses: item.courses,
            estimatedPrice: item.estimatedPrice,
            actualPrice: item.actualPrice,
            reason: item.reason,
            status: item.status,
          }
        })
        const exportData = rows.map(item => {
          const courseList = item.courses.map(course => {
            return {
              name: course.courseItems.length > 0 ? course.courseItems[0].name : null,
              description: course.courseItems.length > 0 ? course.courseItems[0].description : null,
            }
          })
          return {
            menuId: item.id,
            menuName: item.name,
            chefId: item.chefId,
            chefName: item.chefName,
            course1name: courseList[0]?.name,
            course1description: courseList[0]?.description,
            course2name: courseList[1]?.name,
            course2description: courseList[1]?.description,
            course3name: courseList[2]?.name,
            course3description: courseList[2]?.description,
            course4name: courseList[3]?.name,
            course4description: courseList[3]?.description,
          }
        })
        this.setState({
          csvExportData: exportData,
        })
      }
    })
  }
  fetchMenuList() {
    const contextThis = this
    const { pagination, selectedChefId, searchZip, selectedCityId } = this.state
    const data = {
      chefId: selectedChefId > 0 ? selectedChefId : undefined,
      search: '',
      zip: searchZip ? searchZip : '',
      pagination: pagination,
      sorter: {
        field: 'Status',
        order: 'ascend',
      },
    }
    if (selectedCityId) {
      data.cityId = selectedCityId
    }
    if (this.state.showArchivedMenu) {
      data.status = 3
    }
    this.setState({
      loading: true,
    })
    menuClient.list(data).then(res => {
      if (res.status === httpStatus.OK) {
        const rows = res.data.data.map(item => {
          return {
            id: item.id,
            name: item.name,
            categoryName: item.categoryName,
            chefId: item.chefId,
            chefName: item.chefFirstName + ' ' + item.chefLastName,
            description: item.description,
            imageUrls: item.imageUrls,
            price: item.price,
            tags: item.tags,
            courses: item.courses,
            estimatedPrice: item.estimatedPrice,
            actualPrice: item.actualPrice,
            reason: item.reason,
            status: item.status,
          }
        })
        const tableData = rows //.filter((x) => parseInt(x.status) !== 3)
        contextThis.setState({
          menuData: tableData,
          total: res.data.total,
          loading: false,
        })
      }
    })
  }

  handleStatus() {
    this.handleModalVisibility(false)
    const id = this.state.menuActionId
    const status = this.state.menuActionType
    const actualPrice = this.state.menuActionActualPrice
    const reason = this.state.menuActionReason
    menuClient
      .changeStatus(id, status, actualPrice, reason)
      .then(res => {
        if (res.status === 200) {
          this.fetchMenuList()
          menuClient.pendingCount().then(res => {
            if (res.status === httpStatus.OK) {
              document.getElementsByClassName('customElementPendingMenu')[0].innerText = res.data
            }
          })
        }
      })
      .catch(error => {
        // handle error
        console.error(error)
        this.setState({ loading: false })
      })
  }
  handleArchiveMenu(record) {
    if (record) {
      this.setState({
        loading: true,
      })
      const context = this
      menuClient
        .archive({
          id: record.id,
          status: 3,
        })
        .then(res => {
          if (res.status === httpStatus.OK) {
            notification.success({
              message: 'Menu archived',
              description: 'Menu has been successfully archived!',
            })
            context.fetchMenuList()
            menuClient.pendingCount().then(res => {
              if (res.status === httpStatus.OK) {
                document.getElementsByClassName('customElementPendingMenu')[0].innerText = res.data
              }
            })
          }
        })
    }
  }
  handleUnArchiveMenu(record) {
    if (record) {
      this.setState({
        loading: true,
      })
      const context = this
      menuClient
        .archive({
          id: record.id,
          status: 1,
        })
        .then(res => {
          if (res.status === httpStatus.OK) {
            notification.success({
              message: 'Menu unarchived',
              description: 'Menu has been successfully unarchived!',
            })
            context.fetchMenuList()
            menuClient.pendingCount().then(res => {
              if (res.status === httpStatus.OK) {
                document.getElementsByClassName('customElementPendingMenu')[0].innerText = res.data
              }
            })
          }
        })
    }
  }
  handleDeleteMenu(record) {
    if (record) {
      this.setState({
        loading: true,
      })
      menuClient
        .delete(record.id)
        .then(response => {
          if (response.status === httpStatus.OK) {
            this.fetchMenuList()
            notification.success({
              message: 'Menu is successfully deleted!',
            })
          } else {
            this.setState({ loading: false })
          }
        })
        .catch(error => {
          this.setState({ loading: false })
        })
    }
  }
  handleDuplicateMenu(record) {
    if (record) {
      this.setState({
        loading: true,
      })
      menuClient.duplicate(record.id).then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
          })
          notification.success({
            message: 'Successfully created a new menu!',
            duration: 2,
            description: 'Redirecting...',
          })
          this.props.history.push({
            pathname: '/menu-details',
            state: {
              menuId: res.data,
              prevMenuListPagination: this.state.pagination,
              prevChefId: this.state.selectedChefId,
              prevCityId: this.state.selectedCityId,
            },
          })
          menuClient.pendingCount().then(res => {
            if (res.status === httpStatus.OK) {
              document.getElementsByClassName('customElementPendingMenu')[0].innerText = res.data
            }
          })
        }
      })
    }
  }

  handleInputReason() {
    let txtValue = document.getElementById('txtReason').value
    this.setState({
      menuActionReason: txtValue,
    })
  }
  handleCreateMenu() {
    this.props.history.push({
      pathname: '/menu-create',
      state: {
        prevMenuListPagination: this.state.pagination,
        prevChefId: this.state.selectedChefId,
        prevCityId: this.state.selectedCityId,
      },
    })
  }
  async handleArchiveSwitch(e) {
    await this.setState({
      showArchivedMenu: e,
    })
    this.fetchMenuList()
  }
  async onSearch(e) {
    await this.setState({
      searchZip: e,
    })
    this.fetchMenuList()
  }
  async handlePaginationChange(page, pageSize) {
    await this.setState({
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    })
    this.fetchMenuList()
  }
  handleRecommendedList = id => {
    fetch(`https://foodbite.savorwe.com/recommendation/foodRecommendationMenu/${id}`, { method: 'GET' }).then(res => {
      if (res.status === 200) {
        res.json().then(data => {
          if (data !== 'Menu or course is empty') {
            this.setState({
              recommendedList: JSON.parse(data),
              recommendVisible: true,
            })
          } else {
            notification.warn({ message: 'Menu or course is empty' })
          }
        })
      }
    })
    axios.create({ 'Content-Type': 'application/json' }).get(`https://foodbite.savorwe.com/recommendation/foodRecommendationMenu/${id}`)
  }

  render() {
    const { menuData, loading, pagination, total, chefList, cityList, csvExportData } = this.state
    const contextThis = this
    const columns = [
      {
        title: 'Picture',
        width: '130px',
        dataIndex: 'imageUrls',
        render: function(record) {
          if (record) {
            return (
              <Carousel
                autoplay
                style={{
                  maxWidth: '160px',
                  maxHeight: '200px',
                  overflow: 'hidden',
                }}
              >
                {record.map((imageUrl, idx) => {
                  return <LazyImage key={idx} src={imageUrl} alt="placeHolder" />
                })}
              </Carousel>
            )
          }
        },
      },
      {
        title: 'Name',
        width: '200px',
        dataIndex: 'name',
        render: (text, record) => (
          <>
            <p>{record.name}</p>
            {record.description && (
              <Collapse>
                <Panel header="Description" key={record.description}>
                  {record.description}
                </Panel>
              </Collapse>
            )}
          </>
        ),
      },
      {
        title: 'Chef',
        dataIndex: 'chefName',
      },
      {
        title: 'Category',
        dataIndex: 'categoryName',
      },
      {
        title: 'Tags',
        render: function(record) {
          return (
            <div>
              {record.tags &&
                record.tags.map((el, idx) => (
                  <Tag key={idx} color="#2db7f5">
                    {el}
                  </Tag>
                ))}
            </div>
          )
        },
      },
      {
        title: 'Courses',
        width: '300px',
        render: record => {
          return (
            <div>
              {record.courses.length > 0 ? (
                <>
                  <p>Courses</p>
                  <Collapse>
                    {record.courses.map((course, idx) => {
                      return (
                        <Panel header={course.name} key={idx}>
                          <Tag color="#2db7f5" className="mb-3">
                            {course.courseType}
                          </Tag>
                          {course.courseItems.length > 0 &&
                            course.courseItems.map((courseItem, idx) => (
                              <div key={idx}>
                                <p>
                                  <b>{courseItem.name}</b>
                                </p>
                                <p>{courseItem.description}</p>
                              </div>
                            ))}
                        </Panel>
                      )
                    })}
                  </Collapse>
                </>
              ) : (
                <div style={{ color: '#aaa' }}>No Courses Available</div>
              )}
            </div>
          )
        },
      },
      {
        title: 'Estimated Price',
        render: record => (
          <div>
            <span>$</span>
            {record.estimatedPrice}
          </div>
        ),
      },
      {
        title: 'Status',
        width: '220px',
        render: record => {
          if (record.status === 0) {
            let declineKey = 'decline-' + record.id
            let approveKey = 'approve-' + record.id
            return (
              <div>
                Pending
                <div>
                  <Button onClick={() => this.handleModalVisibility(true, 'decline', record.id)} key={declineKey} type="danger" className="mr-2">
                    Decline
                  </Button>
                  <Button onClick={() => this.handleModalVisibility(true, 'approve', record.id)} key={approveKey} type="primary">
                    Approve
                  </Button>
                </div>
              </div>
            )
          } else if (record.status === 1) {
            return (
              <div>
                Approved
                <p>
                  Actual Price: $<span>{record.actualPrice}</span>
                </p>
              </div>
            )
          } else if (record.status === 2) {
            return (
              <div>
                <Tag color="#f50">Declined</Tag>
                <p>
                  Reason: <span>{record.reason}</span>
                </p>
              </div>
            )
          }
        },
      },
      {
        title: 'Action',
        width: '130px',
        render: (text, record) =>
          record.status === 3 ? (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={() => this.handleUnArchiveMenu(record)}>
                <Button>Unarchive</Button>
              </Popconfirm>
              <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={() => this.handleDeleteMenu(record)}>
                <Tooltip placement="bottom" title="Delete">
                  <DeleteOutlined style={{ fontSize: 22, marginLeft: 16, cursor: 'pointer', color: '#999' }} />
                </Tooltip>
              </Popconfirm>
            </div>
          ) : (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Tooltip placement="bottom" title="Edit">
                <EditOutlined
                  style={{ fontSize: 22, marginRight: 8, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push({
                      pathname: '/menu-details',
                      state: {
                        menuId: record.id,
                        prevMenuListPagination: this.state.pagination,
                        prevChefId: this.state.selectedChefId,
                        prevCityId: this.state.selectedCityId,
                      },
                    })
                  }}
                />
              </Tooltip>

              <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={() => this.handleArchiveMenu(record)}>
                <Tooltip placement="bottom" title="Archive">
                  <SaveOutlined style={{ fontSize: 22, marginRight: 8, cursor: 'pointer', color: '#1890ff' }} />
                </Tooltip>
              </Popconfirm>
              <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={() => this.handleDuplicateMenu(record)}>
                <Tooltip placement="bottom" title="Duplicate">
                  <CopyOutlined style={{ fontSize: 22, marginRight: 8, cursor: 'pointer', color: '#d85845' }} />
                </Tooltip>
              </Popconfirm>
              <Popconfirm title="Are you sure you want to delete this menu?" okText="Yes" cancelText="No" onConfirm={() => this.handleDeleteMenu(record)}>
                <Tooltip placement="bottom" title="Delete">
                  <DeleteOutlined style={{ fontSize: 22, marginRight: 8, cursor: 'pointer', color: '#999' }} />
                </Tooltip>
              </Popconfirm>
              {/* {record?.courses.length > 0 ? (
                <Popconfirm title="Are you sure?" okText="Yes" cancelText="No" onConfirm={() => this.handleRecommendedList(record.id)}>
                  <Tooltip placement="bottom" title="Recommends">
                    <SelectOutlined style={{ fontSize: 22, marginRight: 8, cursor: 'pointer', color: '#584949' }} />
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="bottom" title="No courses">
                  <SelectOutlined style={{ fontSize: 22, marginRight: 8, color: '#584949', cursor: 'no-drop' }} />
                </Tooltip>
              )} */}
            </div>
          ),
      },
    ]
    return (
      <Portlet fluidHeight={true}>
        <PortletHeader title="Menu" />
        <PortletBody>
          <div>
            <Row justify="space-between" className="mb-3">
              <Col flex="auto">
                <Row gutter={[15, 15]}>
                  <Col>
                    <div>Chef:</div>
                    <Select
                      showSearch
                      allowClear={false}
                      style={{ width: 320, marginBottom: '1rem' }}
                      value={this.state.selectedChefId}
                      optionFilterProp="children"
                      defaultValue={0}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={async value => {
                        await this.setState({ selectedChefId: value })
                        this.fetchMenuList()
                      }}
                    >
                      <Option value={0} key={0}>
                        All
                      </Option>
                      {chefList &&
                        chefList.length > 0 &&
                        chefList.map(el => (
                          <Option value={el.id} key={el.id}>
                            {el.name}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                  <Col style={{ alignSelf: 'center' }}>
                    <Switch onChange={e => contextThis.handleArchiveSwitch(e)} />
                    <Text style={{ marginLeft: '0.5rem' }}>Show Archived Only</Text>
                  </Col>
                  <Col>
                    <div>City name:</div>
                    <Select
                      showSearch
                      allowClear={false}
                      value={this.state.selectedCityId}
                      style={{ width: 320, marginBottom: '1rem' }}
                      onChange={async value => {
                        await this.setState({ selectedCityId: value })
                        this.fetchMenuList()
                      }}
                      optionFilterProp="children"
                      defaultValue={0}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value={0} key={0}>
                        All
                      </Option>
                      {cityList &&
                        cityList.length > 0 &&
                        cityList.map(function(el) {
                          return (
                            <Option value={el.id} key={el.id}>
                              {el.name}
                            </Option>
                          )
                        })}
                    </Select>
                  </Col>
                  <Col style={{ alignSelf: 'center' }}>
                    <span className="ml-4">
                      <CSVLink data={csvExportData} headers={csvExportHeader} filename={'menu-list.csv'} className="ant-btn">
                        <i className="fa fa-download mr-2"></i>
                        Export to .csv
                      </CSVLink>
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col flex="100px">
                <Button onClick={this.handleCreateMenu}>
                  <i className="kt-menu__ver-arrow la la-plus" />
                  Add Menu
                </Button>
              </Col>
            </Row>
            <Table
              className="menu-list-table"
              dataSource={menuData}
              bordered={true}
              columns={columns}
              rowKey={record => record.id}
              loading={loading}
              pagination={false}
              style={{ color: '#111!important' }}
            />
            <Pagination
              className="p-3 float-right"
              showSizeChanger
              onShowSizeChange={this.handlePaginationChange}
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={total}
              onChange={this.handlePaginationChange}
            />
          </div>
          <Modal
            title={this.state.modalTitle}
            centered
            visible={this.state.modalVisible}
            onOk={() => this.handleStatus()}
            onCancel={() => this.handleModalVisibility(false)}
          >
            {this.state.menuActionType === 2 ? (
              <>
                Reason:
                <TextArea id="txtReason" rows={4} onChange={this.handleInputReason}></TextArea>
              </>
            ) : this.state.menuActionType === 1 ? (
              <InputNumber
                defaultValue={0}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={this.handleInputPrice}
              />
            ) : (
              ''
            )}
          </Modal>
          <Modal
            title="Recommended Menu List"
            centered
            visible={this.state.recommendVisible}
            onCancel={() => this.setState({ recommendVisible: false })}
            width={'90%'}
          >
            <div className="row">
              {this.state.recommendedList.length > 0
                ? this.state.recommendedList.map(data => {
                    return (
                      <div className="col-xl-4 col-lg-6 mb-4">
                        <div class="card card-custom gutter-b card-stretch">
                          <div class="card-body">
                            <div class="d-flex align-items-center">
                              <div
                                class="flex-shrink-0 mr-4 symbol symbol-65 symbol-circle"
                                style={{ width: 100, height: 100, overflow: 'hidden', marginBottom: 5, borderRadius: 10, background: '#dbdbdb' }}
                              >
                                <Carousel
                                  autoplay
                                  style={{
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {data.ImageIds.map((imageUrl, idx) => {
                                    return (
                                      <LazyImage
                                        key={idx}
                                        src={'https://savorwe-backend-staging.azurewebsites.net/api/Common/ImageById/' + imageUrl}
                                        alt="placeHolder"
                                      />
                                    )
                                  })}
                                </Carousel>
                              </div>
                              <div class="d-flex flex-column mr-auto">
                                <div class="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1">Chef Name: {data.ChefName}</div>
                                <span class="text-muted font-weight-bold">Menu Description: {data.MenuDescription}</span>
                              </div>
                            </div>
                            <div class="d-flex">
                              <div class="kt-list-timeline">
                                <div class="kt-list-timeline__items">
                                  {data.CourseItem.map(item => {
                                    return (
                                      <>
                                        <div class="kt-list-timeline__item" style={{ padding: 0 }}>
                                          <span class="kt-list-timeline__badge"></span>
                                          <span class="kt-list-timeline__text">{item.CourseNAME}</span>
                                          <span class="kt-list-timeline__time">{item.CourseCatagoryName}</span>
                                          <span class="kt-list-timeline__time">{item.CourseTYPE}</span>
                                        </div>
                                        <div style={{ paddingLeft: 10, fontSize: 12 }}>{item.CourseDESCRIPTION}</div>
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : 'Menu or course is empty'}
            </div>
          </Modal>
        </PortletBody>
      </Portlet>
    )
  }
}

export default MenuList
