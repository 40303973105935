import ApiClient from "./client";
const client = new ApiClient();

export default {
  list() {
    return client.post("/api/Coupon/List", {
      "Content-Type": "application/json",
    });
  },

  get(id) {
    return client.get("/api/Coupon/" + id, {
      "Content-Type": "application/json",
    });
  },

  create(coupon) {
    return client.post(
      "/api/Coupon",
      {
        name: coupon.name,
        code: coupon.code,
        valid: coupon.valid,
        amountOff: coupon.amountOff,
        percentOff: coupon.percentOff,
        currency: coupon.currency,
        firstTimeTransaction: coupon.firstTimeTransaction,
        minimumAmount: coupon.minimumAmount,
        minimumAmountCurrency: coupon.minimumAmountCurrency,
        dateExpires: coupon.dateExpires,
        maxRedemptions: coupon.maxRedemptions,
      },
      {
        "Content-Type": "application/json",
      }
    );
  },

  update(coupon, id) {
    return client.put(
      "/api/Coupon/" + id,
      {
        name: coupon.name,
        code: coupon.code,
        valid: coupon.valid,
        amountOff: coupon.amountOff,
        percentOff: coupon.percentOff,
        currency: coupon.currency,
        firstTimeTransaction: coupon.firstTimeTransaction,
        minimumAmount: coupon.minimumAmount,
        minimumAmountCurrency: coupon.minimumAmountCurrency,
        dateExpires: coupon.dateExpires,
        maxRedemptions: coupon.maxRedemptions,
      },
      {
        "Content-Type": "application/json",
      }
    );
  },

  delete(id) {
    return client.delete("/api/Coupon/" + id, {
      "Content-Type": "application/json",
    });
  },
};
